<div class="center-center-wrapper">
  <rr-progress-when-first-loading [loading]="firstLoading$ | async">
    <mat-card>
      <mat-progress-bar *ngIf="loading$ | async" color="warn" mode="indeterminate"></mat-progress-bar>
      <mat-card-header>
        <mat-card-title>{{ 'RECOMMENDATION.SETTINGS.PARAMETERS' | transloco }}</mat-card-title>
      </mat-card-header>
      <mat-card-content class="pt-1">
        <ng-container *ngIf="selectedRecommendationSettingType === recommendationSettingTypeEnum.SINGLE_MONTH; else multiMonthTpl">
          <form [formGroup]="form" novalidate>
            <div fxLayout="row">
              <div class="w-100 pt-1">
                <h3 style="font-weight: 500" class="m-0">
                  {{ 'RECOMMENDATION.SETTINGS.LINKS.TITLE' | transloco }}
                </h3>
                <div class="d-flex flex-column">
                  <ul>
                    <li>
                      <a
                        class="setting-link"
                        [routerLink]="workDaysRoute"
                        [matTooltip]="'RECOMMENDATION.SETTINGS.LINKS.WORK_AND_HOLIDAYS.TOOLTIP' | transloco"
                      >
                        {{ 'RECOMMENDATION.SETTINGS.LINKS.WORK_AND_HOLIDAYS.TITLE' | transloco }}
                      </a></li
                    >
                    <li>
                      <a
                        class="setting-link"
                        [routerLink]="vehiclePartnerJoinRoute"
                        [queryParams]="{ selectDefaultSelectedCar: true }"
                        [matTooltip]="'RECOMMENDATION.SETTINGS.LINKS.PARTNERS.TOOLTIP' | transloco"
                      >
                        {{ 'RECOMMENDATION.SETTINGS.LINKS.PARTNERS.TITLE' | transloco }}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div fxLayout="row">
              <div fxLayout="column" class="w-100">
                <div *ngIf="!isPrivateCar" fxLayout="column" class="wid100">
                  <h3 class="wid100" style="font-weight: 500">{{ 'RECOMMENDATION.SETTINGS.PRIVATE_COMPANY_RATIO' | transloco }}</h3>
                  <div fxLayout="row" fxLayoutGap="8px" class="road-rate-wrapper">
                    <mat-form-field class="company-road-rate-form-field">
                      <mat-label>{{ 'RECOMMENDATION.SETTINGS.COMPANY.LABEL' | transloco }}</mat-label>
                      <input [formControl]="companyDistanceRateControl" placeholder="..." matInput name="company_road_rate" />
                      <span matSuffix>%</span>
                      <mat-error>
                        <rr-validation-messages [errors]="companyDistanceRateControl?.errors"></rr-validation-messages>
                      </mat-error>
                    </mat-form-field>
                    <mat-slider
                      (input)="companyDistanceRateControl.patchValue($event.value)"
                      [thumbLabel]="true"
                      [value]="companyDistanceRateControl?.value"
                      invert="true"
                      class="wid100"
                    >
                    </mat-slider>
                    <mat-form-field class="private-road-rate-form-field">
                      <mat-label>{{ 'RECOMMENDATION.SETTINGS.PRIVATE.LABEL' | transloco }}</mat-label>
                      <input [formControl]="privateDistanceRateControl" placeholder="..." matInput name="company_road_rate" />
                      <span matSuffix>%</span>
                      <mat-error>
                        <rr-validation-messages [errors]="privateDistanceRateControl?.errors"></rr-validation-messages>
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>

                <div fxLayout="column" *ngIf="isPrivateCar">
                  <h3 class="wid100" style="margin-bottom: -10px; font-weight: 500">
                    {{ 'RECOMMENDATION.SETTINGS.PAYOFF.LABEL' | transloco }}
                  </h3>
                  <div
                    class="wid100 mt-1"
                    formGroupName="payoff_settings"
                    fxLayout="row"
                    fxLayoutGap="8px"
                    [ngClass]="{
                      'p-setting-container': payoffTypeControl?.value === SETTLEMENT_SETTINGS_ENUM.RAN_DISTANCE && isHUAppType == false
                    }"
                  >
                    <mat-button-toggle-group
                      class="setting-toggle-group primary-color-checked"
                      [formControl]="payoffTypeControl"
                      [matTooltip]="
                        !isPrivateCar
                          ? ('RECOMMENDATION.SETTINGS.PAYOFF.VALIDATION.NOT_SETTABLE_WITH_COMPANY_VEHICLE' | transloco)
                          : undefined
                      "
                      name="setting"
                    >
                      <mat-button-toggle
                        [value]="SETTLEMENT_SETTINGS_ENUM.RAN_DISTANCE"
                        [matTooltip]="'RECOMMENDATION.SETTINGS.PAYOFF.RAN_DISTANCE.TOOLTIP' | transloco"
                      >
                        {{ 'RECOMMENDATION.SETTINGS.PAYOFF.RAN_DISTANCE.LABEL' | transloco }}
                      </mat-button-toggle>
                      <mat-button-toggle
                        [value]="SETTLEMENT_SETTINGS_ENUM.COST"
                        [matTooltip]="'RECOMMENDATION.SETTINGS.PAYOFF.COST.TOOLTIP' | transloco"
                      >
                        {{ 'RECOMMENDATION.SETTINGS.PAYOFF.COST.LABEL' | transloco }}
                      </mat-button-toggle>
                    </mat-button-toggle-group>

                    <mat-form-field
                      *ngIf="payoffTypeControl?.value === SETTLEMENT_SETTINGS_ENUM.COST || isHUAppType === true"
                      style="width: 110px"
                    >
                      <mat-label>
                        <ng-container *ngIf="payoffTypeControl?.value === SETTLEMENT_SETTINGS_ENUM.COST; else ranDistanceLabelTpl">
                          <span [@fadeInEnter]>
                            {{ 'RECOMMENDATION.SETTINGS.PAYOFF.COST.LABEL' | transloco }}
                          </span>
                        </ng-container>
                        <ng-template #ranDistanceLabelTpl>
                          <span [@fadeInEnter]>
                            {{ 'RECOMMENDATION.SETTINGS.PAYOFF.RAN_DISTANCE.LABEL' | transloco }}
                          </span>
                        </ng-template>
                      </mat-label>
                      <input
                        [formControl]="costValueControl"
                        [placeholder]="
                          (payoffTypeControl?.value === SETTLEMENT_SETTINGS_ENUM.COST
                            ? 'RECOMMENDATION.SETTINGS.PAYOFF.COST.LABEL'
                            : 'RECOMMENDATION.SETTINGS.PAYOFF.RAN_DISTANCE.LABEL'
                          ) | transloco
                        "
                        matInput
                        [imask]="costNumberMask"
                        unmask="typed"
                        name="cost"
                      />
                      <div matSuffix>
                        <ng-container *ngIf="payoffTypeControl?.value === SETTLEMENT_SETTINGS_ENUM.COST; else ranDistanceTpl">
                          <span [@fadeInEnter]>{{ 'CURRENCIES.DEFAULT' | transloco }}</span></ng-container
                        >
                        <ng-template #ranDistanceTpl
                          ><span [@fadeInEnter]>{{ 'COMMON.UNIT.KM' | transloco }}</span></ng-template
                        >
                      </div>
                      <mat-error>
                        <rr-validation-messages [errors]="costValueControl?.errors"></rr-validation-messages>
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>

                <ng-container
                  *ngIf="payoffTypeControl?.value === SETTLEMENT_SETTINGS_ENUM.RAN_DISTANCE && isHUAppType === false && isPrivateCar"
                >
                  <div fxLayout="row" fxLayoutGap="0.5em" class="justify-content-between">
                    <mat-form-field style="width: 148px">
                      <mat-label>
                        {{ 'RECOMMENDATION.SETTINGS.PAYOFF.COMPANY_DISTANCE.LABEL' | transloco }}
                      </mat-label>
                      <input
                        [formControl]="companyDistanceControl"
                        [placeholder]="'RECOMMENDATION.SETTINGS.PAYOFF.COMPANY_DISTANCE.LABEL' | transloco"
                        matInput
                        [imask]="maxOfDistanceNumberMask"
                        unmask="typed"
                        name="max_of_distance"
                      />
                      <div matSuffix>
                        <span [@fadeInEnter]>{{ 'COMMON.UNIT.KM' | transloco }}</span>
                      </div>
                      <mat-error>
                        <rr-validation-messages [errors]="companyDistanceControl?.errors"></rr-validation-messages>
                      </mat-error>
                    </mat-form-field>

                    <mat-form-field style="width: 148px">
                      <mat-label>
                        {{ 'RECOMMENDATION.SETTINGS.PAYOFF.PRIVATE_DISTANCE.LABEL' | transloco }}
                      </mat-label>
                      <input
                        [formControl]="privateDistanceControl"
                        [placeholder]="'RECOMMENDATION.SETTINGS.PAYOFF.PRIVATE_DISTANCE.LABEL' | transloco"
                        matInput
                        [imask]="maxOfDistanceNumberMask"
                        unmask="typed"
                        name="private_distance"
                      />
                      <div matSuffix>
                        <span [@fadeInEnter]>{{ 'COMMON.UNIT.KM' | transloco }}</span>
                      </div>
                      <mat-error>
                        <rr-validation-messages [errors]="privateDistanceControl?.errors"></rr-validation-messages>
                      </mat-error>
                    </mat-form-field>

                    <mat-form-field style="width: 100px">
                      <mat-label>
                        {{ 'RECOMMENDATION.SETTINGS.PAYOFF.FREQUENCY.LABEL' | transloco }}
                      </mat-label>
                      <mat-select [formControl]="payoffFrequencyControl">
                        <mat-option *ngFor="let item of recommendationPayOffFrequencies" [value]="item.key">
                          {{ item.value }}
                        </mat-option>
                      </mat-select>
                      <mat-error>
                        <rr-validation-messages [errors]="payoffFrequencyControl?.errors"></rr-validation-messages>
                      </mat-error>
                    </mat-form-field>
                  </div>
                </ng-container>

                <mat-form-field
                  class="w-100"
                  [ngStyle]="isPrivateCar ? { 'margin-top.px': 3 } : { 'margin-top.px': -2 }"
                  *ngIf="!isPrivateCar || isHUAppType === false"
                >
                  <mat-label>{{ 'RECOMMENDATION.SETTINGS.DESTINATION_USAGE_RULE.LABEL' | transloco }}</mat-label>
                  <mat-select
                    panelClass="partner-distance-usage-rule-select"
                    [formControl]="privateDestinationsUsageRuleControl"
                    name="privateDestinationsUsageRule"
                  >
                    <mat-option *ngFor="let opt of privateDestinationsUsageRuleControlSettings" [value]="opt.value">
                      {{ opt.key | transloco }}
                    </mat-option>
                  </mat-select>
                  <mat-error>
                    <rr-validation-messages [errors]="privateDestinationsUsageRuleControl?.errors"></rr-validation-messages>
                  </mat-error>
                </mat-form-field>

                <ng-container formGroupName="daily_settings">
                  <div fxLayout="column" [ngClass]="{ 'mt-2': isPrivateCar }">
                    <h3 class="wid100" style="font-weight: 500">
                      {{ 'RECOMMENDATION.SETTINGS.DAY_SETTINGS.LABEL' | transloco }}
                    </h3>
                    <div fxLayout="row" fxLayoutGap="2em">
                      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="1em">
                        <mat-checkbox
                          [formControl]="useMaxOfDistanceControl"
                          (change)="$event.checked ? maxOfDistanceControl.enable() : maxOfDistanceControl.disable()"
                        >
                        </mat-checkbox>
                        <mat-form-field class="max-of-distance-field" style="width: 140px" floatLabel="always">
                          <mat-label>{{ 'RECOMMENDATION.SETTINGS.MAX_OF_DISTANCE.LABEL' | transloco }}</mat-label>
                          <input
                            [formControl]="maxOfDistanceControl"
                            matInput
                            [imask]="maxOfDistanceNumberMask"
                            unmask="typed"
                            name="max_of_distance"
                            [placeholder]="'RECOMMENDATION.SETTINGS.MAX_OF_DISTANCE.PLACEHOLDER' | transloco"
                          />
                          <span matSuffix>{{ 'COMMON.UNIT.KM' | transloco }}</span>
                          <mat-hint>{{ 'RECOMMENDATION.SETTINGS.MAX_OF_DISTANCE.HINT' | transloco }}</mat-hint>
                          <mat-error>
                            <rr-validation-messages [errors]="maxOfDistanceControl?.errors"></rr-validation-messages>
                          </mat-error>
                        </mat-form-field>
                      </div>
                      <div class="wid100" formGroupName="count_of_destinations">
                        <h4 style="margin-bottom: -10px">
                          {{ 'RECOMMENDATION.SETTINGS.DAY_SETTINGS.NUMBER_OF_DAILY_STATIONS' | transloco }}
                        </h4>
                        <div fxLayout="row" fxLayoutGap="8px">
                          <mat-form-field style="width: 60px">
                            <mat-label>{{ 'RECOMMENDATION.SETTINGS.DAY_SETTINGS.MIN' | transloco }}</mat-label>
                            <mat-select
                              panelClass="min-select"
                              [formControl]="minControl"
                              [matTooltip]="
                                minControl?.value === -1 ? ('RECOMMENDATION.SETTINGS.DAY_SETTINGS.TURNED_OFF' | transloco) : undefined
                              "
                              name="min"
                              [placeholder]="'RECOMMENDATION.SETTINGS.DAY_SETTINGS.MIN' | transloco"
                            >
                              <mat-option [matTooltip]="'RECOMMENDATION.SETTINGS.DAY_SETTINGS.TURNED_OFF' | transloco" [value]="-1"
                                >{{ 'RECOMMENDATION.SETTINGS.DAY_SETTINGS.OFF' | transloco }}
                              </mat-option>
                              <mat-option *ngFor="let val of minControlSettings" [value]="val"> {{ val }}</mat-option>
                            </mat-select>
                            <mat-error>
                              <rr-validation-messages [errors]="minControl?.errors"></rr-validation-messages>
                            </mat-error>
                          </mat-form-field>
                          <mat-form-field style="width: 60px">
                            <mat-label>{{ 'RECOMMENDATION.SETTINGS.DAY_SETTINGS.MAX' | transloco }}</mat-label>
                            <mat-select
                              panelClass="max-select"
                              [formControl]="maxControl"
                              [matTooltip]="
                                maxControl?.value === -1 ? ('RECOMMENDATION.SETTINGS.DAY_SETTINGS.TURNED_OFF' | transloco) : undefined
                              "
                              name="max"
                              [placeholder]="'RECOMMENDATION.SETTINGS.DAY_SETTINGS.MAX' | transloco"
                            >
                              <mat-option [matTooltip]="'RECOMMENDATION.SETTINGS.DAY_SETTINGS.TURNED_OFF' | transloco" [value]="-1"
                                >{{ 'RECOMMENDATION.SETTINGS.DAY_SETTINGS.OFF' | transloco }}
                              </mat-option>
                              <mat-option *ngFor="let val of maxControlSettings" [value]="val"> {{ val }}</mat-option>
                            </mat-select>
                            <mat-error>
                              <rr-validation-messages [errors]="maxControl?.errors"></rr-validation-messages>
                            </mat-error>
                          </mat-form-field>
                        </div>
                        <div *ngIf="countOfDestinationGroup?.errors" class="count-of-destination-group-error" style="margin-top: -12px">
                          <rr-validation-messages
                            [errors]="countOfDestinationGroup?.errors"
                            [messages]="countOfDestinationGroupErrorMessages"
                          ></rr-validation-messages>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div fxLayout="column" class="pt-4">
                    <mat-slide-toggle
                      color="primary"
                      [formControl]="ensureBusinessTripDaily"
                      [matTooltip]="'RECOMMENDATION.SETTINGS.ENSURE_BUSINESS_TRIP_DAILY.TOOLTIP' | transloco"
                    >
                      {{ 'RECOMMENDATION.SETTINGS.ENSURE_BUSINESS_TRIP_DAILY.LABEL' | transloco }}
                    </mat-slide-toggle>
                  </div>
                </ng-container>
              </div>
              <div fxLayout="column" class="right-side w-100">
                <mat-slide-toggle
                  *ngIf="isPrivateCar === true && isSelfEmployedCar === false && (null | appTypeIsHu)"
                  formControlName="use_default_delegation_time"
                  name="use_default_delegation_time"
                  class="mb-2"
                  >{{ 'RECOMMENDATION.SETTINGS.USE_DEFAULT_DELEGATION_TIME.LABEL' | transloco }}
                </mat-slide-toggle>
                <mat-form-field class="w-100">
                  <mat-label>{{ 'RECOMMENDATION.SETTINGS.DISTANCE_USAGE_RULE.LABEL' | transloco }}</mat-label>
                  <mat-select
                    panelClass="partner-distance-usage-rule-select"
                    [formControl]="partnerDistanceUsageRuleControl"
                    name="partnerDistanceUsageRule"
                  >
                    <mat-option *ngFor="let opt of partnerDistanceUsageRuleControlSettings" [value]="opt.value">
                      {{ opt.key | transloco }}
                    </mat-option>
                  </mat-select>
                  <mat-error>
                    <rr-validation-messages [errors]="partnerDistanceUsageRuleControl?.errors"></rr-validation-messages>
                  </mat-error>
                </mat-form-field>
                <mat-form-field class="w-100 custom-width">
                  <mat-label>{{ 'RECOMMENDATION.SETTINGS.MONTHLY_RECURRENCE.LABEL' | transloco }}</mat-label>
                  <mat-select
                    panelClass="partner-distance-usage-rule-select"
                    [formControl]="partnerMonthlyRecurrenceControl"
                    name="privateDestinationsUsageRule"
                  >
                    <mat-option *ngFor="let opt of partnerMonthlyRecurrenceControlSettings" [value]="opt.value">
                      {{ opt.key | transloco }}
                    </mat-option>
                  </mat-select>
                  <mat-error>
                    <rr-validation-messages [errors]="partnerMonthlyRecurrenceControl?.errors"></rr-validation-messages>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
          </form>
        </ng-container>
        <ng-template #multiMonthTpl>
          <form [formGroup]="periodicalForm" novalidate class="h-100">
            <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="10px" class="w-100 h-100 pt-3">
              <div fxFlex="50">
                <div fxLayout="column" style="padding-top: 10px">
                  <h3 class="w-100" style="margin-bottom: -10px; font-weight: 500">
                    {{ 'RECOMMENDATION.SETTINGS.DEDUCTION_SETTINGS.CALCULATION_TITLE' | transloco }}
                  </h3>
                  <div fxLayout="column" class="wid100 mt-1" fxLayoutGap="8px">
                    <div fxLayout="row" class="w-100">
                      <mat-button-toggle-group
                        class="setting-toggle-group primary-color-checked"
                        [formControl]="calculationBaseControl"
                        name="setting"
                      >
                        <mat-button-toggle
                          [value]="SETTLEMENT_SETTINGS_ENUM.RAN_DISTANCE"
                          [matTooltip]="'RECOMMENDATION.SETTINGS.PAYOFF.RAN_DISTANCE.TOOLTIP' | transloco"
                        >
                          {{ 'RECOMMENDATION.SETTINGS.PAYOFF.RAN_DISTANCE.LABEL' | transloco }}
                        </mat-button-toggle>
                        <mat-button-toggle
                          [value]="SETTLEMENT_SETTINGS_ENUM.COST"
                          [matTooltip]="'RECOMMENDATION.SETTINGS.PAYOFF.COST.TOOLTIP' | transloco"
                        >
                          {{ 'RECOMMENDATION.SETTINGS.PAYOFF.COST.LABEL' | transloco }}
                        </mat-button-toggle>
                      </mat-button-toggle-group>
                    </div>
                    <div fxLayout="row" class="w-100" fxLayoutGap="10px">
                      <ng-container *ngIf="calculationBaseControl.value === SETTLEMENT_SETTINGS_ENUM.RAN_DISTANCE; else costTpl">
                        <mat-form-field class="w-50">
                          <mat-label>
                            {{ 'RECOMMENDATION.SETTINGS.DEDUCTION_SETTINGS.COMPANY_DISTANCE.LABEL' | transloco }}
                          </mat-label>
                          <input
                            #companyDistanceInput
                            [formControl]="companyPeriodDistanceControl"
                            matInput
                            [imask]="maxOfPeriodDistanceNumberMask"
                            unmask="typed"
                            name="payoff_value"
                          />
                          <div matSuffix>
                            <span [@fadeInEnter]>{{ 'COMMON.UNIT.KM' | transloco }}</span>
                          </div>
                          <mat-error>
                            <rr-validation-messages [errors]="privatePeriodDistanceControl?.errors"></rr-validation-messages>
                          </mat-error>
                        </mat-form-field>
                        <mat-form-field class="w-50">
                          <mat-label>
                            {{ 'RECOMMENDATION.SETTINGS.DEDUCTION_SETTINGS.PRIVATE_DISTANCE.LABEL' | transloco }}
                          </mat-label>
                          <input
                            #privateDistanceInput
                            [formControl]="privatePeriodDistanceControl"
                            matInput
                            [imask]="maxOfPeriodPrivateDistanceNumberMask"
                            unmask="typed"
                            name="payoff_value"
                          />
                          <div matSuffix>
                            <span [@fadeInEnter]>{{ 'COMMON.UNIT.KM' | transloco }}</span>
                          </div>
                          <mat-error>
                            <rr-validation-messages [errors]="companyPeriodDistanceControl?.errors"></rr-validation-messages>
                          </mat-error>
                        </mat-form-field>
                      </ng-container>
                      <ng-template #costTpl>
                        <mat-form-field class="w-50">
                          <mat-label>
                            {{ 'RECOMMENDATION.SETTINGS.DEDUCTION_SETTINGS.COST.LABEL' | transloco }}
                          </mat-label>
                          <input
                            #deductionInput
                            [formControl]="deductionControl"
                            matInput
                            [imask]="maxOfPeriodCostNumberMask"
                            unmask="typed"
                            name="payoff_value"
                          />
                          <div matSuffix>
                            {{ 'CURRENCIES.DEFAULT' | transloco }}
                          </div>
                          <mat-error>
                            <rr-validation-messages [errors]="deductionControl?.errors"></rr-validation-messages>
                          </mat-error>
                        </mat-form-field>
                      </ng-template>
                    </div>
                  </div>
                </div>
                <div fxLayout="column" fxLayoutGap="5px" style="padding-top: 10px">
                  <h3 class="w-100" style="margin-bottom: -10px; font-weight: 500">
                    {{ 'RECOMMENDATION.SETTINGS.DEDUCTION_SETTINGS.SELECTED_PERIOD_TITLE' | transloco }}
                  </h3>
                  <div fxLayout="row" fxLayoutGap="8px" class="w-100 mt-1">
                    <rr-year-month-field
                      style="max-width: 213px"
                      [parentForm]="periodicalForm"
                      [maxDate]="endDate"
                      [minDate]="startDate"
                      [yearMonthControl]="startYearMonthControl"
                      labelTranslateKey="REPORT.FILTER.FIELD.START_YEAR_MONTH"
                    >
                    </rr-year-month-field>

                    <rr-year-month-field
                      style="max-width: 213px"
                      [parentForm]="periodicalForm"
                      [maxDate]="endDate"
                      [minDate]="startDate"
                      [yearMonthControl]="endYearMonthControl"
                      labelTranslateKey="REPORT.FILTER.FIELD.END_YEAR_MONTH"
                    >
                    </rr-year-month-field>
                  </div>
                </div>
              </div>
              <div style="border-left: 2px dashed rgba(0, 0, 0, 0.12); width: 2px"></div>
              <div fxFlex="50">
                <div fxLayout="row" class="w-100 mt-1 scroll" fxLayoutGap="5px">
                  <table class="periodicalTable">
                    <thead>
                      <th [style.width.%]="columnWidths[0]">
                        {{ 'RECOMMENDATION.SETTINGS.DEDUCTION_SETTINGS.PERIOD_TABLE.COLUMNS.PERIOD' | transloco }}
                      </th>
                      <th [style.width.%]="columnWidths[1]">
                        {{
                          calculationBaseControl.value === SETTLEMENT_SETTINGS_ENUM.COST
                            ? ('RECOMMENDATION.SETTINGS.DEDUCTION_SETTINGS.PERIOD_TABLE.COLUMNS.COST' | transloco)
                            : ('RECOMMENDATION.SETTINGS.DEDUCTION_SETTINGS.PERIOD_TABLE.COLUMNS.COMPANY_DISTANCE' | transloco)
                        }}
                      </th>
                      <th *ngIf="hasThirdColumn" [style.width.%]="columnWidths[2]">
                        {{ 'RECOMMENDATION.SETTINGS.DEDUCTION_SETTINGS.PERIOD_TABLE.COLUMNS.PRIVATE_DISTANCE' | transloco }}
                      </th>
                    </thead>
                    <tbody>
                      <tr *ngFor="let row of periodDetailsArray?.controls; let i = index">
                        <td>{{ row.get('period')?.value | dateFormat }}</td>
                        <td>
                          <mat-form-field class="table-mat-form-field">
                            <input matInput [formControl]="$any(row.get('value'))" />
                            <div matSuffix>
                              {{
                                calculationBaseControl.value === SETTLEMENT_SETTINGS_ENUM.COST
                                  ? ('CURRENCIES.DEFAULT' | transloco)
                                  : ('COMMON.UNIT.KM' | transloco)
                              }}
                            </div>
                            <mat-error>
                              <rr-validation-messages [errors]="row.get('value')?.errors"></rr-validation-messages>
                            </mat-error>
                          </mat-form-field>
                        </td>
                        <td *ngIf="hasThirdColumn">
                          <mat-form-field class="table-mat-form-field">
                            <input matInput [formControl]="$any(row.get('private_distance'))" />
                            <div matSuffix>
                              {{
                                calculationBaseControl.value === SETTLEMENT_SETTINGS_ENUM.COST
                                  ? ('CURRENCIES.DEFAULT' | transloco)
                                  : ('COMMON.UNIT.KM' | transloco)
                              }}
                            </div>
                            <mat-error>
                              <rr-validation-messages [errors]="row.get('private_distance')?.errors"></rr-validation-messages>
                            </mat-error>
                          </mat-form-field>
                        </td>
                      </tr>
                    </tbody>
                    <tfoot *ngIf="periodDetailsArray?.controls">
                      <ng-container *ngIf="getSumPeriodValues() as sums">
                        <tr>
                          <td colspan="2" style="text-align: right">
                            <strong>
                              {{ sums.column1 | number }}
                              {{
                                calculationBaseControl.value === SETTLEMENT_SETTINGS_ENUM.COST
                                  ? ('CURRENCIES.DEFAULT' | transloco)
                                  : ('COMMON.UNIT.KM' | transloco)
                              }}
                            </strong>
                          </td>
                          <td *ngIf="hasThirdColumn" style="text-align: right">
                            <strong>{{ sums.column2 | number }}</strong>
                            {{
                              calculationBaseControl.value === SETTLEMENT_SETTINGS_ENUM.COST
                                ? ('CURRENCIES.DEFAULT' | transloco)
                                : ('COMMON.UNIT.KM' | transloco)
                            }}
                          </td>
                        </tr>
                      </ng-container>
                    </tfoot>
                  </table>
                </div>
              </div>
            </div>
          </form>
        </ng-template>
      </mat-card-content>

      <mat-card-actions class="justify-content-between">
        <button mat-icon-button (click)="onBack()" [matTooltip]="'RECOMMENDATION.SETTINGS.BUTTONS.BACK.TOOLTIP' | transloco">
          <mat-icon svgIcon="arrow-left"></mat-icon>
        </button>
        <div>
          <span
            [matTooltip]="'RECOMMENDATION.SETTINGS.BUTTONS.NAVIGATION.MULTI_MONTH.DISABLED_TOOLTIP' | transloco"
            [matTooltipDisabled]="!disableMultiMonthRecommendation"
          >
            <button
              type="button"
              color="accent"
              mat-raised-button
              (click)="setSettingPage()"
              [disabled]="disableMultiMonthRecommendation"
              *ngIf="visibleMultiMonthRecommendation"
            >
              {{ 'RECOMMENDATION.SETTINGS.BUTTONS.NAVIGATION.' + selectedRecommendationSettingType + '.TITLE' | transloco }}
            </button>
          </span>
          <button
            type="button"
            (click)="onSubmit(true)"
            [disabled]="(form['submitted'] && form.invalid) || form.disabled"
            [ngClass]="{ 'invalid-form': form['submitted'] && form.invalid }"
            class="submit-button"
            color="primary"
            mat-raised-button
          >
            <div
              *ngIf="form['submitted'] && form.invalid"
              class="invalid-form-button-overlay"
              matTooltip="{{ form['submitted'] === true && form.invalid ? ('COMMON.VALIDATION.BUTTON' | transloco) : undefined }}"
              matTooltipPosition="left"
            >
              <mat-icon svgIcon="alert"></mat-icon>
            </div>
            {{ 'RECOMMENDATION.SETTINGS.SAVE_AND_RECOMMEND.' + selectedRecommendationSettingType | transloco }}
          </button>
        </div>
      </mat-card-actions>
    </mat-card>
  </rr-progress-when-first-loading>
</div>

<ng-template #dialogStatusContentTpl>
  <img src="assets/images/partner/import/kavey2.png" class="mx-auto multi-month-image pt-5" alt="Recommendation in progress" />
  <p class="mx-auto pt-4 multi-month-info-text" [innerHTML]="'RECOMMENDATION.SETTINGS.MULTI_MONTH_STATUS_WINDOW.TEXT' | transloco"> </p>
</ng-template>

<ng-template #dialogStatusButtonsTpl>
  <button mat-raised-button color="primary" class="submit-button" (click)="onClickNavigateToList()">
    {{ 'RECOMMENDATION.SETTINGS.MULTI_MONTH_STATUS_WINDOW.BUTTON' | transloco }}
  </button>
</ng-template>
