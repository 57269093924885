import { HTTP_INTERCEPTORS, HttpErrorResponse } from '@angular/common/http';
import {
  AfterContentInit,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidatorFn } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { STORAGE_ENGINE } from '@ngxs/storage-plugin';
import { Actions, ofActionDispatched, ofActionSuccessful, Store } from '@ngxs/store';
import {
  FocusInputDirective,
  slideLeftInUnathAnimation,
  slideLeftUnathOutAnimation,
  USER_FORGOT_PASSWORD,
  ViewportService,
  WINDOW,
} from '@roadrecord/common/common';
import {
  FieldHttpError,
  HandleErrorObject,
  handleHttpError,
  IS_WEBADMIN,
  MaybeHandleHttpError,
  RRHttpErrorResponse,
  ViewRemoteErrorsModel,
} from '@roadrecord/utils';
import { MessageDialogService } from '@roadrecord/message-dialog';
import { isNil } from '@roadrecord/type-guard';
import { rrFormErrorStateMatcher } from '@roadrecord/validating';
import { default as jwt_decode } from 'jwt-decode';
import { RecaptchaComponent } from 'ng-recaptcha';
import { race, Subscription, timer } from 'rxjs';
import { RRStorageEngine, RRStorageRouterExcludePathsToken } from '../../../rr-storage.engine';
import { LoginErrorAction } from '../../../state/action/login/login-error.action';
import { LoginSuccessAction } from '../../../state/action/login/login-success.action';
import { LoginAction } from '../../../state/action/login/login.action';
import { AuthService } from '../../auth.service';
import { UserModel } from '../../model/user.model';
import { fromForgotPassword } from '../forgot-password/forgot-password/from-forgot-password';
import { userOsName } from '@roadrecord/main-helper';
import { FingerprintService } from '../fingerprint/fingerprint.service';
import { UserLoginModel } from '../../model/user-login-model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  GetDateFirstDayWeekSettingAction,
  GetDateFirstDayWeekSettingSuccessAction,
  updateFirstDayOfWeek,
} from '@roadrecord/user/application-settings';
import { DateAdapter } from '@angular/material/core';
import { environment } from '@roadrecord/environment';

// https://stackoverflow.com/questions/44356016/disable-caps-lock-detection-for-soft-keyboards-mobile-browsers

@UntilDestroy()
@Component({
  selector: 'rr-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  providers: [rrFormErrorStateMatcher, FingerprintService],
  animations: [slideLeftInUnathAnimation, slideLeftUnathOutAnimation],
})
export class LoginComponent implements OnInit, OnDestroy, AfterContentInit, AfterViewInit {
  @HostBinding('@enterAnimation')
  enterAnimation;
  @HostBinding('@.disabled')
  disabledAnimation = this.authService.unauthFirstAnimDisabled;
  @HostBinding('@leaveAnimation')
  leaveAnimation;
  visible = false;
  readonly useCaptcha: boolean;
  readonly reCaptchaKey: string;
  passwordType = 'password';
  form: FormGroup & { submitted?: boolean };
  emailControl = new FormControl('', this.customRemoteValidatorMessage('email'));
  passwordControl = new FormControl('', this.customRemoteValidatorMessage('password'));
  @ViewChild('captchaRef')
  readonly captchaRef: RecaptchaComponent;
  @ViewChild('focusEmail', { static: true })
  readonly focusEmail: FocusInputDirective;
  @ViewChild('focusPassword', { static: true })
  readonly focusPassword: FocusInputDirective;
  @ViewChild('emailInput', { static: true })
  readonly emailInput: ElementRef;
  @ViewChild('passwordInput', { static: true })
  readonly passwordInput: ElementRef;
  forgotPasswordRoutePath = USER_FORGOT_PASSWORD;
  private loginErrorActionSubscription: Subscription;
  private loginSuccessRedirectStateSubscription: Subscription;
  private runCaptchaReset = false;
  private httpErrorInterceptor: { removeDisableErrorCode(code: number): void; addDisableErrorCode(code: number): void };
  private remoteControlsErrorsLastValue: { email?: string; password?: string };
  private remoteControlsErrors: ViewRemoteErrorsModel;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private translocoService: TranslocoService,
    private viewportService: ViewportService,
    @Inject(IS_WEBADMIN) readonly isWebadmin: boolean,
    private cdr: ChangeDetectorRef,
    private store: Store,
    private actions: Actions,
    private authService: AuthService<UserModel>,
    @Inject(STORAGE_ENGINE) private storageEngine: RRStorageEngine,
    @Inject(RRStorageRouterExcludePathsToken) private rrStorageRouterExcludePathsToken: string[] = [],
    @Inject(WINDOW) private window: Window,
    private messageDialogService: MessageDialogService,
    @Inject(HTTP_INTERCEPTORS) httpInterceptors: any[],
    private fingerprintService: FingerprintService,
    private dateAdapter: DateAdapter<unknown>
  ) {
    this.httpErrorInterceptor = httpInterceptors.find(
      httpInterceptor => httpInterceptor.ID !== undefined && httpInterceptor.ID === 'HttpError'
    );
    if (environment.useCaptcha) {
      this.useCaptcha = environment.useCaptcha;
      this.reCaptchaKey = environment.captchaCode;
    }
    // vissza allitjuk az elfelejtett jelszo route figyelo flag-et (password-reset-sent route vedelem)
    fromForgotPassword.is = false;
  }

  ngAfterContentInit(): void {
    if (!isNil(this.route.snapshot.queryParams.email)) {
      this.focusPassword.focusElement(1650);
    } else {
      this.focusEmail.focusElement(1650, () => {
        this.cdr.markForCheck();
      });
    }
  }

  initEmailControlValue(): void {
    if (!isNil(this.route.snapshot.queryParams.email)) {
      this.emailControl.patchValue(this.route.snapshot.queryParams.email);
    }
  }

  ngOnInit(): void {
    const errorMsg = this.route.snapshot.queryParams.error_message;
    if (!isNil(errorMsg) && errorMsg.length > 0) {
      const queryParams: any = {};
      if (!isNil(this.route.snapshot.queryParams.email)) {
        queryParams['email'] = this.route.snapshot.queryParams.email;
      }
      this.router.navigate([], { replaceUrl: true, queryParams });
      let errorJson: any;
      try {
        errorJson = { error: JSON.parse(errorMsg) };
        handleHttpError(
          errorJson,
          this.window,
          this.translocoService,
          this.messageDialogService,
          this.router,
          (__error: RRHttpErrorResponse, translocoService: TranslocoService) =>
            HandleErrorObject.handleErrorObject(errorJson).subscribe(() => {})
        );
      } catch (error) {
        console.error(error);
      }
    }

    this.initEmailControlValue();

    this.form = new FormGroup({
      email: this.emailControl,
      password: this.passwordControl,
    });

    this.handleGetDateFirstDayWeekSettingSuccessAction();
  }

  login(recaptchaCode: string): void {
    this._ngOnDestroy();

    const user = this.getUser();
    user.captcha_token = recaptchaCode || undefined;
    this.remoteControlsErrorsLastValue = undefined;

    race([this.actions.pipe(ofActionSuccessful(LoginSuccessAction)), this.actions.pipe(ofActionDispatched(LoginErrorAction))]).subscribe(
      action => {
        if (action instanceof LoginSuccessAction) {
          this.storageEngine.user = jwt_decode(action.token);
          this.store.dispatch(new GetDateFirstDayWeekSettingAction());
        } else {
          if (this.useCaptcha) {
            this.runCaptchaReset = true;
            this.captchaRef.reset();
          }
          if (this.showError(action.error) === false) {
            MaybeHandleHttpError.maybeHandleHttpError(action.error, () => {
              this.focusEmail.focusElement(100);
            });
          }

          if (action.error instanceof FieldHttpError && !isNil(action.error.error) && !isNil(action.error.error.errors)) {
            this.remoteControlsErrors = (action.error.error.errors as unknown) as ViewRemoteErrorsModel;
            this.remoteControlsErrorsLastValue = {};
            Object.entries(this.remoteControlsErrors).forEach(entry => {
              if (entry[0] === 'email') {
                this.remoteControlsErrorsLastValue.email = this.emailControl.value;
              } else if (entry[0] === 'password') {
                this.remoteControlsErrorsLastValue.password = this.passwordControl.value;
              }
            });
          }

          this.form.enable();
        }
        this.httpErrorInterceptor.removeDisableErrorCode(423);
      }
    );
    this.httpErrorInterceptor.addDisableErrorCode(423);
    this.store.dispatch(new LoginAction(user));
  }

  changeVisibility($event): void {
    $event.preventDefault();
    $event.stopPropagation();

    this.visible = !this.visible;
    this.passwordType === 'password' ? (this.passwordType = 'text') : (this.passwordType = 'password');
  }

  onKeyupForm($event): boolean | void {
    $event.stopPropagation();
    $event.preventDefault();

    this.onSubmit();
  }

  showLockOutModal(errorBody: any): void {
    HandleErrorObject.handleErrorObject(errorBody, undefined, {
      title: 'USER.LOGIN.ACCOUNT_LOCKED',
      customTypeImage: 'assets/images/animat-coffee-color.gif',
      type: undefined,
    });
  }

  @HostListener('@enterAnimation.done')
  onEnterDone(): void {
    this.viewportService.routerAnimation = false;
  }

  @HostListener('@enterAnimation.start')
  onLeaveStart(): void {
    this.viewportService.routerAnimation = true;
  }

  onSubmit(): void {
    this.form.submitted = true;
    this.form.disable();
    this.cdr.markForCheck();

    if (this.useCaptcha) {
      this.captchaRef.execute();
    } else {
      this.login('');
    }
  }

  ngOnDestroy(): void {
    this._ngOnDestroy();
  }

  /**
   * Azert kell levalasztani, mert hivjuk a login metodusbol is,
   * es a sima ngOnDestroy hivasa eseten az untilDestroy is vegre hajtodna
   */
  private _ngOnDestroy() {
    if (this.loginErrorActionSubscription !== undefined && this.loginErrorActionSubscription.closed === false) {
      this.loginErrorActionSubscription.unsubscribe();
    }
    if (this.loginSuccessRedirectStateSubscription !== undefined && this.loginSuccessRedirectStateSubscription.closed === false) {
      this.loginSuccessRedirectStateSubscription.unsubscribe();
    }
  }

  onResolveCaptcha($event: string): void {
    if (this.runCaptchaReset === true) {
      this.runCaptchaReset = false;
      this.cdr.detectChanges();
      if ($event !== null) {
        this.onResolveCaptcha($event);
      }
      return;
    }
    if ($event === null) {
      this.messageDialogService
        .openError({ id: null, text: 'COMMON.GOOGLE_RECAPTCHA_ERROR', htmlMode: true })
        .afterClosed()
        .subscribe(() => this.form.enable());
      return;
    }
    this.login($event);
  }

  ngAfterViewInit(): void {
    timer(0).subscribe(() => {
      if (this.authService.unauthFirstAnimDisabled === true) {
        this.authService.unauthFirstAnimDisabled = false;
        this.disabledAnimation = this.authService.unauthFirstAnimDisabled;
      }
    });
  }

  customRemoteValidatorMessage(name: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      return this.remoteControlsErrors !== undefined &&
        this.remoteControlsErrors[name] !== undefined &&
        (this.remoteControlsErrorsLastValue === undefined || this.remoteControlsErrorsLastValue[name] === control.value)
        ? this.getErrorMessage(name)
        : undefined;
    };
  }

  onClickRegistrationWithEmail() {
    this.router.navigate(['register']);
  }

  onCaptchaError(error: any) {
    console.error('Error captcha: ', error);
  }

  /**
   * Login utan, ezt hivjuk meg, es ebbol redirectalunk
   */
  private handleGetDateFirstDayWeekSettingSuccessAction() {
    this.actions.pipe(ofActionSuccessful(GetDateFirstDayWeekSettingSuccessAction), untilDestroyed(this)).subscribe(action => {
      updateFirstDayOfWeek(action.response, this.dateAdapter);
      const directUrl = localStorage.getItem('direct-url');
      if (isNil(directUrl)) {
        this.router.navigate(['/']);
      } else {
        const url = new URL(directUrl);
        const redirectUrl = url.hash.replace('#', '');
        this.router.navigateByUrl(redirectUrl);
      }
    });
  }

  private showError(errorResponse: HttpErrorResponse): boolean {
    const locked = 423;
    if (errorResponse.status === locked) {
      this.showLockOutModal(errorResponse);
      return true;
    }
    return false;
  }

  private getUser(): UserLoginModel {
    // eredetileg iOS -t ad vissza
    const isIOS = userOsName === 'ios';
    const email = isIOS ? this.emailInput.nativeElement.value : this.form.value.email.trim();
    const password = isIOS ? this.passwordInput.nativeElement.value : this.form.value.password;
    return {
      email,
      password,
      captcha_token: undefined,
      is_admin_login: this.isWebadmin,
      device_fingerprint: this.fingerprintService.getCode(),
    };
  }

  private getErrorMessage(name: string): { customError: any } {
    return { customError: this.remoteControlsErrors[name] };
  }
}
