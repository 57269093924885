import { UniversalImportController } from '../../base/import-worker.controller';
import { ImportFileTypeEnum, UniversalImportControllerOptions } from '../../base/types';
import { isEmptyString, isNil, isNumeric, isString } from '@roadrecord/type-guard';
import { UniversalImportConfigModel, UniversalImportHeadersHeaderValidatorsModel } from '@roadrecord/worker/universal-import';
import { DataValidationError, FieldValidationError, ServerError } from '@roadrecord/worker/shared';
import { Entries } from 'type-fest';
import { Validators } from '@angular/forms';
import { CsvImportFileType, ExcelImportFileType } from '../../base/file-types';
import 'moment';
import moment from 'moment-timezone';
import { initMomentMSDateResolver } from './moment-msdate.resolver';

initMomentMSDateResolver(moment);

export class ExcelOrCsvUniversalImportController extends UniversalImportController<string[]> {
  constructor(options: UniversalImportControllerOptions) {
    super(options);
    this._fileTypes = [ExcelImportFileType, CsvImportFileType];
  }

  getHeaderFromFile(headerRowIndex: number): string[] {
    return this._fileContent[headerRowIndex];
  }

  /**
   * amikor kulon vesszuk a csv-t es az excel-t akkor nem kell mar megadni
   */
  isCSV() {
    return isEmptyString(this._file.type)
      ? /* windows */ ['csv'].indexOf(this._file.name.split('.').pop()) > -1
      : /* common */ this.fileTypes.find(fileType => fileType.name === ImportFileTypeEnum.CSV).types.indexOf(this._file.type) > -1;
  }

  async fileContentToRows(e: any): Promise<void> {
    if (this.isCSV()) {
      return this.processExcelOrCsvFile(e, true);
    } else {
      return this.processExcelOrCsvFile(e, false);
    }
  }

  private processExcelOrCsvFile(bstr: any, isCSV: boolean) {
    /* read workbook */
    return import('xlsx').then(XLSX => {
      try {
        const workBook = XLSX.read(bstr, {
          type: isCSV ? 'string' : 'array',
          cellDates: !isCSV,
          cellText: isCSV,
          raw: isCSV,
        });

        const wsname = workBook.SheetNames[0];
        const firstSheet = workBook.Sheets[wsname];
        this._fileContent = XLSX.utils.sheet_to_json(firstSheet, {
          header: 1,
          raw: isCSV,
          blankrows: false,
          ...(isCSV ? {} : { dateNF: 'YYYY-MM-DD HH:mm:ss' }),
        });
      } catch (_e) {
        console.error(_e);
        delete this.readerAbort;
        return Promise.reject();
      }
      delete this.readerAbort;
      return Promise.resolve();
    });
  }

  transformAndValidatedRowsToRemoteDataFormat(config: UniversalImportConfigModel): object[] {
    const rows: object[] = [];
    for (let i = 1 + config.removeFirstRowsNumber; i < this._fileContent.length; i++) {
      const row = this.getRowDataByAssociatedHeaders(this._fileContent[i]);
      try {
        if (this.validateExcelOrCsvRow(row)) {
          // sorok atalakitasa tombbol object-e
          const tmpRow: any = {};
          this._associatedHeaders.headers.forEach((header, index) => {
            tmpRow[header.requiredHeader.key] = row[index];
          });
          rows.push(tmpRow);
        }
      } catch (e) {
        if (e instanceof ServerError) {
          throw e;
        }

        const headerName = this._userFileHeaders.find(header => this._associatedHeaders.headers[e.columnIndex].userHeader === header);
        throw new DataValidationError(headerName, i - (1 + config.removeFirstRowsNumber), e.message, e.extraMessageParam);
      }
    }
    return rows;
  }

  private validateExcelOrCsvRow(row: string[]): boolean {
    // check is current month
    // check date
    const options = this._associatedHeaders.options;
    // check required
    this._associatedHeaders.headers.forEach((header, index) => {
      const cellValue = row[index];

      if (header.requiredHeader.validators.req) {
        if (!(!isNil(cellValue) && ((isString(cellValue) && cellValue.length > 0) || isNumeric(cellValue)))) {
          throw new FieldValidationError(index, 'REQUIRED');
        }
      }

      (Object.entries(header.requiredHeader.validators) as Entries<UniversalImportHeadersHeaderValidatorsModel>)
        .filter(entry => entry[0] !== 'req')
        .every(entry => {
          switch (entry[0]) {
            case 'is_numeric':
              if (entry[1] === true && !isNumeric(cellValue)) {
                throw new FieldValidationError(index, 'IS_NUMERIC');
              }
              break;
            case 'min_length':
              if (Validators.minLength(entry[1] as number)({ value: `${cellValue}` } as any) !== null) {
                throw new FieldValidationError(index, 'MIN_LENGTH', { minLength: entry[1] as number });
              }
              break;
            case 'max_length':
              if (Validators.maxLength(entry[1] as number)({ value: `${cellValue}` } as any) !== null) {
                throw new FieldValidationError(index, 'MAX_LENGTH', { maxLength: entry[1] as number });
              }
              break;
            case 'min_value':
              if (isNumeric(entry[1]) && Validators.min(entry[1])({ value: cellValue } as any) !== null) {
                throw new FieldValidationError(index, 'MIN_VALUE', { minValue: entry[1] });
              }
              break;
            case 'max_value':
              if (isNumeric(entry[1]) && Validators.max(entry[1])({ value: cellValue } as any) !== null) {
                throw new FieldValidationError(index, 'MAX_VALUE', { maxValue: entry[1] });
              }
              break;
          }
        });
    });

    let validPeriodDate = true;
    // check by validators
    Object.entries(options.format).forEach(([key, value]) => {
      if (validPeriodDate === false) {
        return;
      }
      const foundIndex = this.findUserHeaderIndexByAssociatedHeaders(key);
      const cellValue = row[foundIndex];

      if (foundIndex > -1) {
        if (
          this._periodContextCurrentYearMonth !== null &&
          isString(value.format_type) &&
          ['DATE', 'DATETIME'].includes(value.format_type)
        ) {
          const dateValidationFormat = value.format;
          const convertedDate = moment(cellValue, dateValidationFormat, true);
          const convertedDateYear = +convertedDate.format('YYYY');

          if (
            !convertedDate.isValid() ||
            convertedDate.format('YYYYMMDDHHmmSSS') === '197001010000000' ||
            convertedDateYear > +moment().format('YYYY')
          ) {
            throw new FieldValidationError(foundIndex, 'DATE');
          }
          if (convertedDate.format(this._periodContextCurrentYearMonthCheckDateFormat) !== this._periodContextCurrentYearMonth) {
            // ha nem adott period context honapra vonatkozik a datum akkor eldobjuk
            validPeriodDate = false;
          }
        } else if (
          (isNil(value.format_type) || value.format_type === 'REGEX') &&
          (isString(cellValue) ? cellValue : '').match(new RegExp(value.format, 'g')) === null
        ) {
          throw new FieldValidationError(foundIndex, value.error);
        }
      }
    });

    return validPeriodDate;
  }

  getRowDataByAssociatedHeaders(row: string[]): string[] {
    const userFileHeaders = this._userFileHeaders;
    const result: string[] = [];
    const options = this._associatedHeaders.options;
    this._associatedHeaders.headers.forEach(header => {
      const foundUserFileHeaderIndex = userFileHeaders.indexOf(header.userHeader);
      let cellData: any = row[foundUserFileHeaderIndex];
      const format = this._associatedHeaders.options.format[header.requiredHeader.key];
      const dateValidationFormat: string | null =
        !isNil(format) && isString(format.format_type) && format.format_type === 'DATE' ? format.format : null;
      if (this._periodContextCurrentYearMonth !== null && dateValidationFormat !== null /* && cellData instanceof Date*/) {
        cellData = moment(
          !this.isCSV() && isNumeric(cellData) ? (moment as any).fromOADate(cellData, undefined) : cellData,
          dateValidationFormat
        ).format(dateValidationFormat);
      } else if (!isNil(format) && isString(format.format_type) && format.format_type === 'TIME') {
        // check is string time and convert to string
        const timeStringMatches = findTimeByRegex(`${cellData}`);
        if (timeStringMatches.length > 0) {
          // Igy eltavolitunk tuti minden felesleges karaktert
          cellData = timeStringMatches[0];
        } else {
          cellData = moment(
            !this.isCSV() && isNumeric(cellData) ? (moment as any).fromOADate(cellData, undefined) : cellData,
            dateValidationFormat
          ).format(format.format);
        }
      }

      result.push(cellData);
    });
    return result;
  }

  /*
  checkPeriodContextCanChange(): MayPeriodChangeModel | null {
    return null;
  }
*/
}

function findTimeByRegex(inputString: string) {
  let extraHours = 0;
  let AMPMMode = false;
  let isPMMode = false;
  if (inputString.endsWith(' AM')) {
    AMPMMode = true;
    inputString = inputString.replace(' AM', '');
  } else if (inputString.endsWith(' PM')) {
    AMPMMode = true;
    isPMMode = true;
    extraHours = 12;
    inputString = inputString.replace(' PM', '');
  }

  if (inputString.search(' ') > -1) {
    inputString = inputString.split(' ')[1];
  }
  // Definiáljuk a regex mintát
  const regexPattern = /^.*(\d{1,2}):(\d{2})(?::(\d{2}))?$/;

  // Keressük meg a mintának megfelelő összes egyezést
  const matches = inputString.match(regexPattern);
  // Visszaadjuk az összes találatot
  if (matches.length > 0) {
    //matches[0] = `${+matches[0] + extraHours}`;
    if (AMPMMode) {
      let [, hour] = matches;
      const [, , min, sec] = matches;
      hour = `${+hour + extraHours}`;
      if (hour.length === 1) {
        hour = `0${hour}`;
      }
      return [`${hour}:${min}:${sec ?? '00'}`];
    } else {
      return [inputString];
    }
  }
  // Visszaadunk egy üres tömböt, ha nincs találat
  return [];
}
