<ng-container
  *rrTransloco="let t; fromPath: 'UNIVERSAL_IMPORT.UPLOAD_STEPPER'; overridePath: importConfig.translatePrefix + '.UPLOAD_STEPPER'"
>
  <div class="d-flex flex-column flex-wrapper" style="overflow-y: auto !important">
    <div class="d-flex flex-row flex-grow-1" style="column-gap: 10px">
      <div class="d-flex flex-column w-50">
        <mat-card
          @fadeIn2XFastEnter
          @fadeOut2XFastLeave
          class="mat-elevation-z4 source-type-description"
          *ngIf="t('SOURCE_TYPE.TYPES.' + selectedSourceTypeTranslateKey + '.DESCRIPTION') as translate"
        >
          <mat-card-title>
            <b style="line-height: normal">{{ t('SOURCE_TYPE.TYPES.' + selectedSourceTypeTranslateKey + '.LABEL') }}</b>
          </mat-card-title>

          <mat-card-content class="pt-2">
            <span [innerHTML]="translate"></span>
          </mat-card-content>
        </mat-card>

        <div class="mt-3 mb-3 buttons-row">
          <a
            *ngIf="importSampleUrl !== null"
            class="d-none hidden-download-sample-link"
            [href]="importSampleUrl"
            target="_blank"
            #downloadFileAnchor
          ></a>
          <a
            *ngIf="t('SOURCE_TYPE.TYPES.' + selectedSourceTypeTranslateKey + '.GYIKURL') as linkUrl"
            [attr.id]="importConfig.gtmPrefix + '_upload_file_faq_' + selectedSourceTypeTranslateKey.toLowerCase()"
            @fadeIn2XFastEnter
            mat-raised-button
            [href]="linkUrl"
            target="_blank"
            color="primary"
            class="w-100 gyik-button"
          >
            {{
              t(
                fileUploadTranslatePrefix +
                  (selectedSourceType.type === universalUploadType ? 'GYIK_BUTTON_LABEL' : 'HELP_FILE_UPLOAD_BUTTON_LABEL')
              )
            }}
            <mat-icon svgIcon="help-circle"></mat-icon>
          </a>

          <button
            *ngIf="importSampleUrl !== null"
            @fadeIn2XFastEnter
            color="accent"
            mat-raised-button
            class="d-inlie-block download-sample w-100"
            (click)="downloadSampleXLSFile()"
          >
            {{ t(fileUploadTranslatePrefix + 'DOWNLOAD_SAMPLE_FILE') }}<mat-icon svgIcon="download-circle"></mat-icon>
          </button>
        </div>
      </div>
      <div
        class="file-drop-zone w-50"
        [ngClass]="{ 'invalid-drag': validDraggingFile === false, 'valid-drag': validDraggingFile === true, disabled: fileDropDisabled }"
        ngfDrop
        selectable="1"
        [accept]="fileAccept"
        [(files)]="files"
        [maxSize]="maxFileSize"
        [fileDropDisabled]="fileDropDisabled"
        (validDragChange)="onValidDrag($event)"
        (fileChange)="onFileChange($event)"
        (lastInvalidsChange)="onLastInvalidsChange($event)"
        [matTooltip]="
          selectedSourceType === undefined ? t(fileUploadTranslatePrefix + 'TOOLTIP_DISABLE_SOURCE_TYPE_HAS_NOT_SELECTED') : undefined
        "
        matTooltipClass="error"
        matTooltipPosition="above"
      >
        <div class="disable-overlay" *ngIf="fileDropDisabled"></div>
        <div class="text-center">
          <!-- kell a 3 container mivel a validDraggingFile 3 allapotu: true, false, undefined -->
          <ng-container *ngIf="validDraggingFile === undefined">
            <mat-icon svgIcon="cloud-upload-outline" class="upload-icon"></mat-icon>
            <button type="button" mat-raised-button color="accent" class="mb-3">{{
              t(fileUploadTranslatePrefix + 'SELECT_FILE.BUTTON_LABEL')
            }}</button>
            <p class="text-center" [innerHTML]="t(fileUploadTranslatePrefix + 'SELECT_FILE.SELECT_FILE')"></p>
            <small class="text-center d-inline-block w-100">{{
              t(
                fileUploadTranslatePrefix +
                  'SELECT_FILE.' +
                  (selectedSourceType.acceptedFileTypes
                    | acceptedFileType
                      : 'HINT_SIZE_WARNING_JUST_CSV'
                      : 'HINT_SIZE_WARNING_JUST_XLSX'
                      : 'HINT_SIZE_WARNING_JUST_JSON'
                      : 'HINT_SIZE_WARNING')
              )
            }}</small>
          </ng-container>
          <ng-container *ngIf="validDraggingFile === false">
            <mat-icon svgIcon="alert" class="upload-icon"></mat-icon>
            <p class="text-center">
              <strong>{{
                t(
                  fileUploadTranslatePrefix +
                    'SELECT_FILE.' +
                    (selectedSourceType.acceptedFileTypes
                      | acceptedFileType
                        : 'NOT_ALLOWED_TYPE_ERROR_JUST_CSV'
                        : 'NOT_ALLOWED_TYPE_ERROR_JUST_XLSX'
                        : 'NOT_ALLOWED_TYPE_ERROR_JUST_JSON'
                        : 'NOT_ALLOWED_TYPE_ERROR')
                )
              }}</strong>
            </p>
          </ng-container>
          <ng-container *ngIf="validDraggingFile === true">
            <mat-icon svgIcon="checkbox-marked-circle-outline" class="upload-icon"></mat-icon>
            <p class="text-center">
              <strong>{{ t(fileUploadTranslatePrefix + 'SELECT_FILE.SUCCESS_SELECTED_FILE_TYPE') }}</strong
              ><br /><small>{{ t(fileUploadTranslatePrefix + 'SELECT_FILE.RESELECT_FILE_HINT_INFO') }}</small>
            </p>
          </ng-container>
          <p class="text-center error-msg pt-1" @fadeInEnter *ngIf="uploadedFileError">{{
            t(uploadedFileError, { size: selectedSourceType.maxFileSize / (1024 * 1024) })
          }}</p>
        </div>
      </div>
    </div>

    <hr class="w-100" />

    <div class="d-flex">
      <button
        [attr.id]="importConfig.gtmPrefix + '_upload_file_back_' + selectedSourceTypeTranslateKey.toLowerCase()"
        type="button"
        @fadeInEnter
        mat-raised-button
        (click)="backToSelectSource.emit()"
        >{{ t('FILE_UPLOAD.BACK') }}</button
      >
      <span class="flex-spacer"></span>
      <button
        [attr.id]="importConfig.gtmPrefix + '_upload_file_start_' + selectedSourceTypeTranslateKey.toLowerCase()"
        [disabled]="fileDropDisabled || selectedFile === undefined"
        @fadeInEnter
        class="start-import-button"
        mat-raised-button
        color="primary"
        (click)="startImport()"
        type="button"
      >
        {{ t(fileUploadTranslatePrefix + 'SUBMIT.LABEL') }}
      </button>
    </div>
  </div>
</ng-container>
