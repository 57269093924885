import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import { DOCUMENT } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  ComponentRef,
  ElementRef,
  Inject,
  Injector,
  OnInit,
  Optional,
  TemplateRef,
  TrackByFunction,
  ViewChild,
} from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { Actions, ofActionDispatched, ofActionSuccessful, Select, Store } from '@ngxs/store';
import { DEFAULT_ROUTE_PATH, globalPhoneControlValidationMessages, MatchesViewportSizes, ViewportService } from '@roadrecord/common/common';
import { MESSAGE_DIALOG_ELEMENT_REF_DATA, MessageDialogService, MessageDialogTypeEnum } from '@roadrecord/message-dialog';
import {
  PreferencesCloseContentWindowAction,
  PreferencesContentWindowTypeEnum,
  PreferencesOpenContentWindowAction,
  PreferencesState,
} from '@roadrecord/preferences-state/common';
import { isFunction, isNil } from '@roadrecord/type-guard';
import { IS_WEBADMIN, MaybeHandleHttpError, ServerSerrorMetaEnum, ThrowHttpErrorWithMetaAction } from '@roadrecord/utils';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, Observable, of, Subscription, timer } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap, tap } from 'rxjs/operators';
import { HotkeyOptionsModel } from '../model/hotkey-options.model';
import { PeriodContextDisplayComponent } from '../period-context-display/period-context-display.component';
import { APP_HOTKEYS } from '../app-hotkeys.token';
import { MenuCloseAction } from '../menu/state/action/menu-close.action';
import { MenuToggleAction } from '../menu/state/action/menu-toggle.action';
import { MenuState } from '../menu/state/menu.state';
import { MenuSetInvalidPeriodContextAction } from '../menu/state/action/menu-set-invalid-period-context.action';
import {
  InvalidPeriodContextEnum,
  PeriodContextRemoveVehicleAction,
  PeriodContextStateSelectorsService,
} from '@roadrecord/period-context/common';
import { VehicleModel } from '@roadrecord/vehicle/model/common';
import { DraggableWindowOptionModel } from '../model/draggable-window-option.model';
import { CallbackOptions, INTROJS_TYPES_ENUM, IntrojsState, StartIntroAction, Step } from '@roadrecord/introjs/common';
import { CompanyContextState, ShowOnSaleEndDateNotificationAction } from '@roadrecord/company-context/common';
import { Options } from 'intro.js';
import { LoadableService } from '@roadrecord/external-packages/ngx-loadable';
import { VehicleService } from '@roadrecord/vehicle/service/common';
import { IdleExpiry, SimpleExpiry } from '@ng-idle/core';
// tslint:disable-next-line:nx-enforce-module-boundaries
import { AppReadyAction } from '../../../../../application-settings/state/src/lib/state/action/app-ready.action';
import { MatDialogRef } from '@angular/material/dialog/dialog-ref';
import { DialogComponent } from '@roadrecord/dialog';
import { AppTypeEnum, environment } from '@roadrecord/environment';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { phoneNumberValidator2 } from '@roadrecord/ngx-phone-validators';
import { PhoneNumberService } from '../service/phone-number.service';

const draggableWindowTrackByFn: TrackByFunction<DraggableWindowOptionModel> = (index, item) => item.type;

@UntilDestroy()
@Component({
  selector: ' rr-app-layout',
  templateUrl: './app-layout.component.html',
  styleUrls: ['./app-layout.component.scss'],
  providers: [
    SimpleExpiry,
    {
      provide: IdleExpiry,
      useExisting: SimpleExpiry,
    },
  ],
})
export class AppLayoutComponent implements OnInit {
  @Select(MenuState.open)
  readonly menuOpened$: Observable<any>;
  /**
   * hasMenuButton allasatol fuggoen over vagy side erteket vesz fel
   */
  sidenavMode: 'over' | 'push' | 'side' = 'side';
  @ViewChild('leftSidenav', { static: true })
  leftSidenav: MatSidenav;
  showSidenav = true;
  @Select(PreferencesState.helpVideoUrl)
  readonly helpVideoUrl$: Observable<string>;

  currentBreakpoint: MatchesViewportSizes;

  readonly draggableWindowOptions$ = new BehaviorSubject<DraggableWindowOptionModel[]>([]);
  draggableWindowContentComponentPortal: { [key: string /*PreferencesContentWindowTypeEnum*/]: ComponentPortal<any> } = {};
  draggableWindowTrackByFn = draggableWindowTrackByFn;
  @Select(IntrojsState.run)
  showIntrojs$: Observable<boolean>;
  @Select(IntrojsState.currentSteps)
  introjsSteps$: Observable<Step[]>;
  @Select(IntrojsState.currentCallbacks)
  introjsCallbacks$: Observable<CallbackOptions>;
  @Select(IntrojsState.globalOptions)
  introjsGlobalOptions$: Observable<Options>;
  private startIntroWatchRouteQueryParamChangeSubscription: Subscription;

  selectedMenuTabIndex = 0;

  phoneNumberDialogRef: MatDialogRef<DialogComponent>;
  readonly isUsAppType = environment.appType === AppTypeEnum.US;
  @ViewChild('phoneNumberInput') phoneNumberInput: ElementRef;
  @ViewChild('phoneDialogButtonsTpl') dialogPhoneButtonsTpl: TemplateRef<any>;
  @ViewChild('phoneDialogContentTpl') dialogPhoneContentTpl: TemplateRef<any>;
  formGroup = new FormGroup({});
  marketingActivityAllowedControl = new FormControl(false);
  phoneNumberControl = new FormControl(environment.production ? '' : '9414139717', Validators.required);
  phoneControlValidationMessages = globalPhoneControlValidationMessages;

  private isAppReadyActionFired = false;
  constructor(
    private viewportService: ViewportService,
    @Inject(DOCUMENT) private document: Document,
    private router: Router,
    private store: Store,
    private actions$: Actions,
    private hotkeysService: HotkeysService,
    private translocoService: TranslocoService,
    private messageDialogService: MessageDialogService,
    @Optional() @Inject(APP_HOTKEYS) private appHotkeys: HotkeyOptionsModel[],
    @Inject(IS_WEBADMIN) private isWebadmin: boolean,
    private loadableService: LoadableService,
    private injector: Injector,
    private matSnackBar: MatSnackBar,
    private cdr: ChangeDetectorRef,
    private vehicleService: VehicleService<VehicleModel>,
    private periodContextSelectors: PeriodContextStateSelectorsService<any, any>,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private phoneNumberService: PhoneNumberService
  ) {
    this.viewportService.openVideoDialog$
      .pipe(
        untilDestroyed(this),
        filter(url => !isNil(url) && url.length > 0)
      )
      .subscribe(url => {
        const title = 'HELP_VIDEO_WINDOW.TITLE';
        this.store
          // ha van nyitva akkor bezarjuk
          .dispatch(new PreferencesCloseContentWindowAction(PreferencesContentWindowTypeEnum.HELP_VIDEO))
          .subscribe(() =>
            this.store.dispatch(
              new PreferencesOpenContentWindowAction(
                PreferencesContentWindowTypeEnum.HELP_VIDEO,
                title,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                url
              )
            )
          );
      });

    this.actions$.pipe(ofActionSuccessful(PreferencesOpenContentWindowAction)).subscribe((action: PreferencesOpenContentWindowAction) => {
      const openedWindows = this.draggableWindowOptions$.getValue();
      if (!openedWindows.some(w => w.type === action.windowType)) {
        this.draggableWindowOptions$.next([
          ...openedWindows.map(w => {
            w.active = false;
            return w;
          }),
          {
            type: action.windowType,
            title: action.title,
            uiOptions: action.uiOptions,
            showMaximizeAndMinimizeButtons: action.showMaximizeAndMinimizeButtons,
            videoUrl: action.videoUrl,
            counter: openedWindows.length,
            active: true,
            inputs: action.inputs,
          },
        ]);
        if (isNil(action.videoUrl)) {
          this.draggableWindowContentComponentPortal[action.windowType] = new ComponentPortal<any>(
            action.cmp,
            null,
            null,
            action.componentFactoryResolver
          );
        }
      } else {
        if (!isNil(action.ngModuleRef)) {
          // ha mar nyitva van akkor az uj moduleref-et el is pusztitjuk...
          action.ngModuleRef.destroy();
        }
      }
    });

    this.actions$.pipe(ofActionSuccessful(PreferencesCloseContentWindowAction)).subscribe(action => {
      if (action.windowType === 'ALL') {
        this.draggableWindowContentComponentPortal = {};
        this.draggableWindowOptions$.next([]);
      } else {
        delete this.draggableWindowContentComponentPortal[action.windowType];
        this.draggableWindowOptions$.next(this.draggableWindowOptions$.getValue().filter(value => value.type !== action.windowType));
      }
    });

    this.actions$.pipe(ofActionDispatched(AppReadyAction)).subscribe((action: AppReadyAction) => {
      if (this.isAppReadyActionFired === false) {
        if (this.phoneNumberService.checkShouldShowPhoneNumberDialog()) {
          this.isAppReadyActionFired = true;
          this.checkShouldShowPhoneNumberDialog(action);
        }
      } else {
        this.isAppReadyActionFired = true;
      }
    });

    this.store
      .select(states => (isWebadmin ? true : states.firstSteps.finished))
      .pipe(
        switchMap(finished =>
          this.store.select(CompanyContextState.isTrialTimeExpired).pipe(map(isExpiredTrialTime => (isExpiredTrialTime ? false : finished)))
        )
      )
      .subscribe(finished => (this.showSidenav = finished));
    this.dispatchWindowResizeEventWhenMenuToggle();
    this.watchInvalidPeriodContextActions();
  }

  private watchInvalidPeriodContextActions() {
    // TODO sort csak mocokolas miatt kell ideiglenesen!
    const getVehicleOne = (sortDirection: 'asc' | 'desc' = 'asc') => {
      return this.vehicleService
        .getAll(
          { active: 'plate_number', direction: sortDirection },
          {
            length: 1,
            pageIndex: 0,
            pageSize: 1,
          },
          ''
        )
        .pipe(untilDestroyed(this));
    };

    let disableNextCheck = false;
    this.store
      .select(MenuState.invalidPeriodContext)
      .pipe(
        filter(state => state === InvalidPeriodContextEnum.FORCE_SELECT_PERIOD_CONTEXT),
        filter(() => {
          if (disableNextCheck) {
            disableNextCheck = false;
            return false;
          }
          return true;
        })
      )
      .subscribe(() => this.processRequirePeriodContext());

    // subscribe error response detect
    this.actions$
      .pipe(
        ofActionSuccessful(ThrowHttpErrorWithMetaAction),
        filter(action => action.error.hasMeta(ServerSerrorMetaEnum.INVALID_PERIOD_CONTEXT)),
        switchMap(() => getVehicleOne())
      )
      .subscribe(list => {
        if (list.results.length === 1) {
          // ha van meg kocsija akkor kotelezo ablakot kap
          disableNextCheck = true;
          this.store.dispatch(new MenuSetInvalidPeriodContextAction(InvalidPeriodContextEnum.FORCE_SELECT_PERIOD_CONTEXT));

          this.processRequirePeriodContext();
        }
      });

    let vehiclePullTimerSubscription: Subscription;
    this.store
      .select(this.periodContextSelectors.runVehiclePull)
      .pipe(distinctUntilChanged())
      .subscribe(runVehiclePull => {
        if (runVehiclePull) {
          vehiclePullTimerSubscription = timer(0, 30 * 1000)
            .pipe(switchMap(() => getVehicleOne('desc')))
            .subscribe(() => {});
        } else if (vehiclePullTimerSubscription !== undefined) {
          if (vehiclePullTimerSubscription.closed === false) {
            vehiclePullTimerSubscription.unsubscribe();
          }
          vehiclePullTimerSubscription = undefined;
        }
      });
  }

  private processRequirePeriodContext() {
    this.router.navigate([DEFAULT_ROUTE_PATH]).then(() => {
      this.store.dispatch(new PeriodContextRemoveVehicleAction());

      this.messageDialogService
        .open(
          {
            id: null,
            elementRef: PeriodContextDisplayComponent,
            elementRefInjector: this.createInjector({
              menuOpened$: of(true),
              contextLoading$: of(false),
              disablePeriodContextLoad: true,
              periodContext: this.store.selectSnapshot(this.periodContextSelectors.context),
              editMode: true,
              hideHeader: true,
            }),
            type: MessageDialogTypeEnum.WARNING,
            enableOk: false,
            enableCancel: false,
          },
          {
            autoFocus: true,
            closeOnNavigation: false,
            disableClose: true,
            restoreFocus: true,
            backdropClass: 'error-backdrop',
          }
        )
        .afterClosed()
        .subscribe(() => this.messageDialogService.matDialog.closeAll());
    });
  }

  createInjector(dataToPass): PortalInjector {
    const injectorTokens = new WeakMap();
    injectorTokens.set(MESSAGE_DIALOG_ELEMENT_REF_DATA, dataToPass);
    return new PortalInjector(this.injector, injectorTokens);
  }

  private dispatchWindowResizeEventWhenMenuToggle(): void {
    this.menuOpened$.pipe(untilDestroyed(this)).subscribe(() => {
      window.dispatchEvent(new Event('resize'));
      timer(350).subscribe(() => window.dispatchEvent(new Event('resize')));
    });
  }

  private isTrialTimeExpired() {
    return this.store.selectSnapshot(CompanyContextState.isTrialTimeExpired);
  }

  private removeDirectURL(): void {
    if (localStorage.getItem('direct-url')) {
      localStorage.removeItem('direct-url');
    }
  }

  ngOnInit(): void {
    this.removeDirectURL();
    this.addAppHotKeys();
    const startIntroActionNeeded = this.store.selectSnapshot(IntrojsState.notShowedFilter(INTROJS_TYPES_ENUM.WELCOME));

    // csak a desktop meret szurese
    this.viewportService.breakpoint$.pipe(untilDestroyed(this)).subscribe(breakpoint => {
      this.currentBreakpoint = breakpoint;
      if (breakpoint.desktop === true && this.sidenavMode !== 'side') {
        this.sidenavMode = 'side';
      } else if (breakpoint.desktop === false && this.sidenavMode !== 'over') {
        this.sidenavMode = 'over';
      }
      if (this.currentBreakpoint.desktop === false) {
        // ha mobil akkor alapbol mindig bezarjuk
        this.dispatchMenuCloseAction();
      }
    });

    // Erre itt azert van szukseg hogy el tudjunk directbe is inditani intro-t stepper vegen
    this.startIntroWatchRouteQueryParamChangeSubscription = this.route.queryParamMap
      .pipe(
        map(queryParamMap => queryParamMap.get('startIntro')),
        filter(v => !isNil(v)),
        switchMap(v => {
          if (this.store.selectSnapshot(CompanyContextState.showOnSaleEndDate)) {
            this.store.dispatch(new ShowOnSaleEndDateNotificationAction());
          }
          if (
            this.isTrialTimeExpired() === false &&
            startIntroActionNeeded &&
            !this.phoneNumberService.checkShouldShowPhoneNumberDialog()
          ) {
            return timer(12 * 350).pipe(switchMap(() => this.store.dispatch(new StartIntroAction(INTROJS_TYPES_ENUM.WELCOME))));
          }
          return of();
        })
      )
      .subscribe(() => {
        this.startIntroWatchRouteQueryParamChangeSubscription.unsubscribe();
      });

    if (this.store.selectSnapshot(states => states.firstSteps.finished) === true) {
      this.startIntroWatchRouteQueryParamChangeSubscription.unsubscribe();

      const checkShouldShowPhoneNumber = this.phoneNumberService.checkShouldShowPhoneNumberDialog();

      if (this.isWebadmin === false && this.isTrialTimeExpired() === false) {
        const showOnSaleEndDateNotificationNeeded = this.store.selectSnapshot(CompanyContextState.showOnSaleEndDate);
        if (checkShouldShowPhoneNumber) {
          this.store.dispatch(
            new AppReadyAction(() => {
              if (startIntroActionNeeded) {
                this.store.dispatch(new StartIntroAction(INTROJS_TYPES_ENUM.WELCOME));
              }

              if (showOnSaleEndDateNotificationNeeded) {
                this.store.dispatch(new ShowOnSaleEndDateNotificationAction());
              }
            })
          );
        } else {
          if (startIntroActionNeeded) {
            this.store.dispatch(new StartIntroAction(INTROJS_TYPES_ENUM.WELCOME));
          }

          if (showOnSaleEndDateNotificationNeeded) {
            this.store.dispatch(new ShowOnSaleEndDateNotificationAction());
          }
        }
      }
    }
  }

  private addAppHotKeys(): void {
    if (Array.isArray(this.appHotkeys)) {
      this.translocoService
        .selectTranslate(this.appHotkeys.map(option => option.title))
        .pipe(
          map(translates => {
            if (this.hotkeysService.hotkeys.length > 0) {
              [...this.hotkeysService.hotkeys].forEach(hotKey => this.hotkeysService.remove(hotKey));
            }
            return this.appHotkeys.map(
              (option, index) =>
                new Hotkey(
                  option.hotKey,
                  option.callback(
                    this.store,
                    this.messageDialogService,
                    this.loadableService,
                    this.injector,
                    this.translocoService,
                    this.matSnackBar,
                    this.periodContextSelectors
                  ),
                  ['INPUT'],
                  translates[index]
                )
            );
          })
        )
        .subscribe(hotkeys => {
          this.hotkeysService.add([
            ...hotkeys,
            new Hotkey(
              '?',
              () => {
                this.hotkeysService.cheatSheetToggle.next();
                return true;
              },
              ['INPUT'],
              this.translocoService.translate('APP_LAYOUT.HOTKEYS.HELP_OPEN')
            ),
            new Hotkey(
              'esc',
              () => {
                this.hotkeysService.cheatSheetToggle.next(false);
                return true;
              },
              ['INPUT'],
              this.translocoService.translate('APP_LAYOUT.HOTKEYS.HELP_CLOSE')
            ),
          ]);
          this.cdr.detectChanges();
        });
    }
  }

  dispatchMenuCloseAction(): void {
    this.store.dispatch(new MenuCloseAction());
  }

  toggleSidenav(): void {
    this.store.dispatch(new MenuToggleAction());
  }

  onCloseDraggableWindow(type: PreferencesContentWindowTypeEnum | string): void {
    // this.draggableWindowOptions$.next(undefined);
    this.store.dispatch(new PreferencesCloseContentWindowAction(type as PreferencesContentWindowTypeEnum));
  }

  onActivateDraggableWindow(type: PreferencesContentWindowTypeEnum) {
    const openedWindowsLength = this.draggableWindowOptions$.getValue().length;
    this.draggableWindowOptions$.next(
      this.draggableWindowOptions$.getValue().map(w => {
        if (w.type === type) {
          w.active = true;
          w.counter = openedWindowsLength;
        } else {
          w.active = false;
          w.counter -= 1;
        }
        return { ...w };
      })
    );
  }

  onBindInputsToAttachedCmp(inputs: { [p: string]: any }, cmp: ComponentRef<any>) {
    if (!isNil(inputs) && Object.keys(inputs).length > 0) {
      Object.entries(inputs).forEach(entry => (cmp.instance[entry[0]] = entry[1]));
    }
  }

  private checkShouldShowPhoneNumberDialog(action: AppReadyAction): void {
    if (this.phoneNumberService.checkShouldShowPhoneNumberDialog()) {
      if (!this.formGroup.contains('phone_number')) {
        this.phoneNumberControl.setValidators(Validators.compose([Validators.required, phoneNumberValidator2]));
        this.phoneControlValidationMessages = [
          {
            errorKey: 'phoneNumber',
            translateKey: 'COMMON.VALIDATION.PHONE.NO_PHONE_NUMBER',
          },
        ];
        this.formGroup.addControl('phone_number', this.phoneNumberControl);
        this.formGroup.addControl('marketing_activity_allowed', this.marketingActivityAllowedControl);
      }
      this.openPhoneNumberDialog(action);
    }
  }

  private openPhoneNumberDialog(action: AppReadyAction) {
    this.phoneNumberDialogRef = this.dialog.open(DialogComponent, {
      maxWidth: 1024,
      disableClose: true,
      panelClass: ['rr-dialog'],
      data: {
        maximizeMode: false,
        hasActionLeftButtons: false,
        buttonsTpl: this.dialogPhoneButtonsTpl,
        contentTpl: this.dialogPhoneContentTpl,
        title: this.translocoService.translate('AFTER_REGISTRATION_PHONE_NUMBER_DIALOG.TITLE'),
      },
    });
    this.phoneNumberDialogRef.afterOpened().subscribe(() => {
      setTimeout(() => {
        this.phoneNumberInput.nativeElement.focus();
      }, 200);
    });
    this.phoneNumberDialogRef.afterClosed().subscribe(() => {
      if (isFunction(action.callback)) {
        action.callback();
      }
    });
  }

  public onClickPhoneSave(): void {
    if (this.formGroup.valid) {
      this.phoneNumberService.savePhoneNumber(this.formGroup.value).subscribe(
        () => {
          this.phoneNumberDialogRef.close();
          this.matSnackBar.open(this.translocoService.translate('COMMON.NOTIFICATION.SUCCESS_SAVE'));
        },
        error => {
          console.log(error);
          MaybeHandleHttpError.maybeHandleHttpError(error);
        }
      );
    }
  }

  parseUsPhoneNumber(value: string) {
    return value.replace(new RegExp(/-/, 'g'), '').replace(new RegExp(/\(/, 'g'), '').replace(new RegExp(/\)/, 'g'), '');
  }
}
