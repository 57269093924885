import { UAParser } from 'ua-parser-js';
import { isFunction, isNil, isNotEmptyString, isString } from '@roadrecord/type-guard';
import { default as jwt_decode } from 'jwt-decode';

export const logHandlers: any = {};

export const selectTextInputsOnFocus = (node): void => {
  node.querySelectorAll('input:not([type="checkbox"]):not(.timeInput):not([initedCustomFocus]):not(.readonly-mode)').forEach(input => {
    if (input.addEventListener) {
      input.addEventListener('focus', () => input.select());
    } else {
      input.attachEvent('onfocus', () => input.select());
    }
    input.setAttribute('initedCustomFocus', true);
  });
};

export const acceptedBrowsers = {
  chrome: {
    ios: '67',
    android: '67',
    macos: '67',
    linux: '67',
    ubuntu: '67',
    unix: '67',
    debian: '67',
    suse: '67',
    redhat: '67',
    openbsd: '67',
    fedora: '67',
    arch: '67',
    windows: '67',
    chromiumos: '67',
  },
  firefox: {
    ios: '18',
    android: '60',
    macos: '60',
    linux: '60',
    ubuntu: '60',
    unix: '60',
    debian: '60',
    suse: '60',
    redhat: '60',
    openbsd: '60',
    fedora: '60',
    arch: '60',
    windows: '60',
    chromiumos: '60',
  },
  edge: {
    windows: '79',
    macos: '79',
  },
  safari: {
    macos: '13',
  },
  mobilesafari: {
    ios: '11',
  },
  chromium: {
    ios: '67',
    android: '67',
    macos: '67',
    linux: '67',
    ubuntu: '67',
    unix: '67',
    debian: '67',
    suse: '67',
    redhat: '67',
    openbsd: '67',
    fedora: '67',
    arch: '67',
    windows: '67',
    chromiumos: '67',
  },
};

export function isOutdatedBrowser(_userBrowserName: string, _userOsName: string, _userBrowserMajorVersion: string) {
  // userBrowserName alapjan kivesszuk a megfelelo kulcsot
  if (acceptedBrowsers[_userBrowserName] !== undefined) {
    // ha van kulcs, akkor ellenorizzuk az os-t
    if (acceptedBrowsers[_userBrowserName][_userOsName] !== undefined) {
      // ellenorizzuk a version-t
      if (+acceptedBrowsers[_userBrowserName][_userOsName] <= +_userBrowserMajorVersion) {
        return false;
      }
    }
  }
  return true;
}

export const NAME = UAParser.BROWSER.NAME;
export const VERSION = UAParser.BROWSER.VERSION;
export const TYPE_BOT = ['type', 'bot'];
export const botsRegExt = [
  // google, bing, msn
  [/((?:google|bing|msn)bot(?:\-[imagevdo]{5})?)\/([\w\.]+)/i],
  [NAME, VERSION, TYPE_BOT],
  // bing preview
  [/(bingpreview)\/([\w\.]+)/i],
  [NAME, VERSION, TYPE_BOT],
];
export const uaParserBotBrowser = new UAParser({ browser: botsRegExt } as any).getBrowser();
export const uaParser = new UAParser();
export const userBrowserInfo = uaParser.getBrowser();
export const userBrowserMajorVersion = userBrowserInfo.major ? userBrowserInfo.major : '0';
export const userBrowserName = userBrowserInfo.name ? userBrowserInfo.name.toLowerCase().replace(new RegExp(' ', 'g'), '') : '';
export const os = uaParser.getOS();
export const userOsName = os.name ? os.name.toLowerCase().replace(new RegExp(' ', 'g'), '') : '';
export const userBrowserIsOutdated = isOutdatedBrowser(userBrowserName, userOsName, userBrowserMajorVersion);

export const preloadImages = (): Promise<any>[] => {
  // Preload background images
  const common = ['/assets/images/common/spritesheet.png', '/assets/images/404/4040flatdesing-x.png'];
  const registerBackgroundImages = {
    i1280: '/assets/background-images/unauth/rr_screen_background_reg_208063_1280x1024px.jpg',
    i1360: '/assets/background-images/unauth/rr_screen_background_reg_208063_1360x768px.jpg',
    i1920: '/assets/background-images/unauth/rr_screen_background_reg_208063_1920x1080px.jpg',
    i4096: '/assets/background-images/unauth/rr_screen_background_reg_208063_4096x2304px.jpg',
  };

  const registrationSuccessfulBackgroundImages = {
    i1280: '/assets/background-images/registration-successful/rr_screen_background_reg_ok_202072_1280x1024px.jpg',
    i1360: '/assets/background-images/registration-successful/rr_screen_background_reg_ok_202072_1360x768px.jpg',
    i1920: '/assets/background-images/registration-successful/rr_screen_background_reg_ok_202072_1920x1080px.jpg',
    i4096: '/assets/background-images/registration-successful/rr_screen_background_reg_ok_202072_4096x2304px.jpg',
  };

  const promises: Promise<any>[] = [];
  // screen size images preload
  const screenWidth = window.innerWidth;
  let index: number;
  if (screenWidth <= 1280) {
    index = 0;
  } else if (screenWidth <= 1360) {
    index = 1;
  } else if (screenWidth <= 1920) {
    index = 2;
  } else if (screenWidth > 1921) {
    index = 3;
  }
  promises.push(
    new Promise<any>(resolve => {
      let imgPath = Object.entries(registerBackgroundImages)[index][1];
      let link: HTMLLinkElement = document.createElement('link');
      link.rel = 'prefetch';
      link.href = imgPath;
      link.as = 'image';
      link.crossOrigin = '';
      document.getElementsByTagName('head')[0].appendChild(link);
      new Image().src = imgPath;

      imgPath = Object.entries(registrationSuccessfulBackgroundImages)[index][1];
      link = document.createElement('link');
      link.rel = 'prefetch';
      link.href = imgPath;
      link.as = 'image';
      link.crossOrigin = '';
      document.getElementsByTagName('head')[0].appendChild(link);
      new Image().src = imgPath;

      resolve();
    })
  );

  // common images preload
  common.forEach(imgPath => {
    promises.push(
      new Promise<any>((resolve, reject) => {
        const link: HTMLLinkElement = document.createElement('link');
        link.rel = 'prefetch';
        link.href = imgPath;
        link.as = 'image';
        link.crossOrigin = '';
        document.getElementsByTagName('head')[0].appendChild(link);
        resolve();
      })
    );
  });
  return promises;
};

export const startDOMChangeDetect = () => {
  const mutationObserver = new MutationObserver((mutations: MutationRecord[]) => {
    mutations.forEach(mutation => {
      Array.from(mutation.addedNodes).forEach((node: any) => {
        if (node !== null && typeof node.querySelector === 'function' && node.querySelector('input') !== null) {
          node.querySelectorAll('input:not([data-with-last-pass]):not([data-with-browser-autocomplete])').forEach(input => {
            input.setAttribute('data-lpignore', true);
            input.autocomplete = 'off';
          });
          node.querySelectorAll('input[data-with-last-pass]').forEach(input => {
            input.setAttribute('data-lpignore', false);
          });
          node.querySelectorAll('input[data-with-browser-autocomplete]').forEach(input => {
            input.autocomplete = 'on';
          });
          selectTextInputsOnFocus(node);
        }
        if (node !== null && typeof node.querySelector === 'function' && node.querySelector('mat-icon') !== null) {
          // material icon google translate fix
          node.querySelectorAll('mat-icon').forEach(matIcon => matIcon.classList.add('notranslate'));
        }
        if (node !== null && typeof node.querySelector === 'function' && node.querySelector('form') !== null) {
          node.querySelectorAll('form').forEach(form => (form.autocomplete = 'off'));
        }
      });
    });
  });
  mutationObserver.observe(document.documentElement, {
    attributes: false,
    childList: true,
    characterData: false,
    subtree: true,
  });
};

export const rrAngularAppBootstrap = (environmentMode: 'local-dev' | 'e2e', userBrowserIsBot: boolean, startFn: () => void) => {
  if (
    environmentMode !== 'local-dev' &&
    environmentMode !== 'e2e' &&
    !userBrowserIsBot &&
    userBrowserIsOutdated &&
    ['ios', 'android'].indexOf(userOsName) === -1
  ) {
    import('./hu-hu.json').then((translate: any) => {
      // OUTDATE browser handling
      const outdatedDivElement = document.createElement('div');
      outdatedDivElement.id = 'outdated';
      'display: table;'.split(';').forEach(_style => {
        const style = _style.split(':');
        outdatedDivElement.style[style[0]] = style[1];
      });

      const divElement = document.createElement('div');
      divElement.classList.add('vertical-center');
      outdatedDivElement.appendChild(divElement);

      const h6Element = document.createElement('h6');
      h6Element.style.color = 'white';
      divElement.appendChild(h6Element);

      let brElement = document.createElement('br');
      h6Element.appendChild(brElement);

      const imgElement = document.createElement('img');
      imgElement.src = 'assets/images/common/felkialtojely.png';
      h6Element.appendChild(imgElement);
      brElement = document.createElement('br');
      h6Element.appendChild(brElement);
      h6Element.appendChild(document.createTextNode(translate.BROWSER_NOT_SUPPORT));

      const supportedBrowserPElement = document.createElement('p');
      supportedBrowserPElement.style.color = 'white';
      const bElement = document.createElement('b');
      bElement.innerText = translate.SUPPORTED_BROWSERS;
      supportedBrowserPElement.appendChild(bElement);
      divElement.appendChild(supportedBrowserPElement);

      const ulElement = document.createElement('ul');
      [
        {
          name: 'Chrome',
          minimumVersion: 67,
          browserDownloadUrl: translate['LINKS.CHROME'],
        },
        {
          name: 'Firefox',
          minimumVersion: 60,
          browserDownloadUrl: translate['LINKS.FIREFOX'],
        },
        {
          name: 'Safari',
          minimumVersion: 11,
          browserDownloadUrl: translate['LINKS.SAFARI'],
        },
        {
          name: 'Microsoft Edge (Chromium)',
          minimumVersion: 79,
          browserDownloadUrl: ['translate.LINKS.EDGE'],
        },
      ].forEach(browser => {
        const liElement = document.createElement('li');
        const _bElement = document.createElement('b');
        _bElement.innerText = browser.name;
        liElement.appendChild(_bElement);

        liElement.appendChild(document.createTextNode(' ('));

        const smallElement = document.createElement('small');
        smallElement.innerText = `${translate.MINIMUM_VERSION} ${browser.minimumVersion}`;
        liElement.appendChild(smallElement);

        liElement.appendChild(document.createTextNode(') '));

        const anchorElement = document.createElement('a');
        anchorElement.href = browser.browserDownloadUrl;
        anchorElement.innerText = translate.DOWNLOAD_CLICK;
        liElement.appendChild(anchorElement);

        ulElement.appendChild(liElement);
      });

      divElement.appendChild(ulElement);

      document.body.insertBefore(outdatedDivElement, document.body.firstChild);

      document.getElementById('page-loader').remove();
      document.getElementById('app-overlay').remove();

      return translate;
    });
  } else {
    const promises = preloadImages();
    Promise.all(promises).then(
      () => startFn(),
      () => startFn()
    );
  }
};

function hasQueryParams() {
  return isString(window.location.search) && window.location.search.length > 0;
}

/**
 * Ha nem adunk meg parametert akkor a teljes listat kapjuk vissza
 * @param paramName
 */
function getQueryParams(paramName?: string) {
  const params = window.location.search
    .split('?')[1]
    .split('&')
    .reduce((acc, next) => {
      if (isNotEmptyString(next)) {
        const splittedValue = next.split('=');
        acc[splittedValue[0]] = splittedValue[1];
      }
      return acc;
    }, {});
  if (isNotEmptyString(paramName)) {
    return params[paramName];
  }
  return params;
}

/**
 * Detect social login server redirect with token
 *
 * @param tokenKey {string}
 */
export function detectSocialLogin(tokenKey: string) {
  if (!hasQueryParams()) {
    return false;
  }
  return detectSwitchUser({
    tokenKey,
    checkStaffId: false,
    hookTrue: queryParams => {
      if (queryParams.hasOwnProperty('registered')) {
        localStorage.setItem('route', 'registration-successful');
      }
    },
  });
}

export function setUserToken(userToken, user) {
  localStorage.setItem(
    `${window.location.hostname}_auth`,
    JSON.stringify({
      token: userToken,
      user,
      version: 4,
    })
  );
}

/**
 * Detect webadmin login as user
 *
 * @param tokenKey {string}
 * @param checkStaffId {boolean}
 */
export function detectSwitchUser({
  tokenKey,
  checkStaffId,
  hookTrue,
  urlCb,
}: {
  tokenKey: string;
  checkStaffId?: boolean;
  hookTrue?: (queryParams: { [key: string]: string }) => void;
  urlCb?: (queryParams: { [key: string]: string }) => string;
}) {
  if (!hasQueryParams()) {
    return false;
  }
  const queryParams = getQueryParams();
  const userToken = queryParams[tokenKey];
  if (isNotEmptyString(userToken)) {
    localStorage.clear();
    const user = jwt_decode(userToken);
    if (checkStaffId === false || user.hasOwnProperty('staff_id')) {
      setUserToken(userToken, user);
      if (isFunction(hookTrue)) {
        hookTrue(queryParams);
      }
      window.location.replace(isFunction(urlCb) ? urlCb(queryParams) : '/');
      return true;
    }
  }
  return false;
}

/**
 * URL pattern összeállítása
 * @param categoryType {string}
 */
function getPriceURL(categoryType: string): string {
  let urlTag = '';
  let urlPattern = '';
  let isLoggedUser = false;

  const urlSearchParam = hasQueryParams() ? `${window.location.search}&` : '?';
  const authObject = localStorage.getItem(`${window.location.hostname}_auth`);

  if (authObject !== null) {
    const authData = JSON.parse(authObject);
    isLoggedUser = authData.hasOwnProperty('token');
  }

  if (isLoggedUser === true) {
    urlTag = '/user';
  }

  urlPattern = `${window.location.origin}/#${urlTag}/price/${urlSearchParam}category_type=${categoryType}`;

  return urlPattern;
}

/**
 * Publikus URL mentése a localStorage-ba
 */
export function shouldSaveURL() {
  const keywords = ['user', 'month-activities', 'basic-data'];
  return keywords.some(keyword => window.location.href.includes(keyword));
}

/**
 * Publikus URL mentése a localStorage-ba, WebAdmin esetén
 */
export function shouldAdminSaveURL() {
  const keywords = ['user'];
  if (keywords.some(keyword => window.location.href.includes(keyword))) {
    localStorage.setItem('direct-url', window.location.href);
  }
}

/**
 * Árlistát érintő URL minták kezelése
 */
export function detectRedirectURL() {
  if (window.location.href.indexOf('/pricing/on-sale/') > -1) {
    const urlPattern = getPriceURL('on-sale');
    window.location.replace(urlPattern);
  } else if (window.location.href.indexOf('/pricing/basic/') > -1) {
    const urlPattern = getPriceURL('basic');
    window.location.replace(urlPattern);
  } else if (shouldSaveURL()) {
    localStorage.setItem('direct-url', window.location.href);
  } else if (!hasQueryParams()) {
    return false;
  } else {
    const queryParams = getQueryParams();
    // Sikeres vagy sikertelen fizetés lekezelése
    if (queryParams.hasOwnProperty('redirect-from') && queryParams.hasOwnProperty('response')) {
      sessionStorage.setItem('price-payment-response', window.location.search);
      // Fizetés a stepperből indult
      const paymentInStepper = sessionStorage.getItem('payment-in-stepper');
      const paymentInTrialTimeExpired = sessionStorage.getItem('payment_in_trial_time');

      if (isNil(paymentInStepper) && isNil(paymentInTrialTimeExpired)) {
        const urlPattern = `${window.location.origin}/#/price/payment-response${window.location.search}`;
        window.location.replace(urlPattern);
      } else if (!isNil(paymentInTrialTimeExpired)) {
        const urlPattern = `${window.location.origin}/#/user/trial-time/expired/payment-response${window.location.search}`;
        window.location.replace(urlPattern);
      }
    } else {
      sessionStorage.removeItem('payment-in-stepper');
      sessionStorage.removeItem('payment_in_trial_time');
      window.location.replace('/');
    }
  }
}
