import { Injectable } from '@angular/core';
import { AbstractEntityService, BatchRequestModel } from '@roadrecord/utils';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@roadrecord/environment';
import { Sort } from '@angular/material/sort';
import { PageEvent } from '@angular/material/paginator';
import { RecommendationPeriodicalModel } from '../model/recommendation-settings.model';

@Injectable({ providedIn: 'root' })
export class PeriodicalService extends AbstractEntityService<any, any> {
  constructor(http: HttpClient) {
    super('recommendation', 'name', http);
  }

  getModelIdValue(model: any): any {
    return '';
  }

  getToString(model: any): string {
    return undefined;
  }

  startPeriodical(data: RecommendationPeriodicalModel): Observable<any> {
    return this.http.post<RecommendationPeriodicalModel>(`${environment.apiUrl}${this._entityUrl}/periodical/`, data);
  }

  getStatusBatch(): BatchRequestModel {
    return { method: 'get', relative_url: `/${this.getBaseApiEndpoint()}/${this.entityUrl}/periodical/status/` };
  }

  getAllBatch(sort: Sort, page: PageEvent, simpleAllFilterValue: string): BatchRequestModel {
    return {
      method: 'get',
      relative_url: this.getListOrResourceUrl(undefined, this.getUrlParameters(sort, page, simpleAllFilterValue)).replace(
        `${this.getApiUrl()}${this.entityUrl}/`,
        `/${this.getBaseApiEndpoint()}/${this.entityUrl}/periodical/list/`
      ),
    };
  }
}
