import { UniversalAssociatedHeadersModel, UniversalImportConfigModel } from '@roadrecord/worker/universal-import';
import { isFunction, isNil, isString } from '@roadrecord/type-guard';
import { FileParseError, FileSizeError, FileTypeError, UnknownTimelineTypeError, ZipContentError } from '@roadrecord/worker/shared';
import { ImportFileType, RecognizedTimelineFileTypeEnum, UniversalImportControllerOptions } from './types';
import JSZip from 'jszip';
import { default as _rollupMoment } from 'moment';

export interface MayPeriodChangeModel {
  year: number;
  month: number;
}

export abstract class UniversalImportController<ROW_TYPE> {
  protected _associatedHeaders: UniversalAssociatedHeadersModel | null = null;
  protected _periodContextCurrentYearMonth: string | null = null;
  protected _periodContextCurrentYearMonthCheckDateFormat: string | null = null;
  private _maxFileSize: number | null = null;
  protected _rows: ROW_TYPE[] | null = null;
  protected _file: File | null = null;
  protected _determinateTimelineFileType = false;
  protected _recognizedTimelineFileType: RecognizedTimelineFileTypeEnum | null = null;

  /**
   * Fajlbol kiolvasott ertek, de meg nincs atalakitva backend szamara
   */
  protected _fileContent: any | null = null;
  protected _fileImportFileType: ImportFileType | null = null;
  protected _fileTypes: ImportFileType[] | null = null;
  protected _userFileHeaders: string[] | null = null;
  protected readerAbort: () => void | null = null;

  protected constructor({
    associatedHeaders,
    periodContextCurrentYearMonth,
    periodContextCurrentYearMonthCheckDateFormat,
    maxFileSize,
  }: UniversalImportControllerOptions) {
    this._associatedHeaders = associatedHeaders;
    this._periodContextCurrentYearMonth = periodContextCurrentYearMonth;
    this._periodContextCurrentYearMonthCheckDateFormat = periodContextCurrentYearMonthCheckDateFormat;
    this._maxFileSize = maxFileSize;
  }

  get associatedHeaders(): UniversalAssociatedHeadersModel {
    return this._associatedHeaders;
  }

  set associatedHeaders(value: UniversalAssociatedHeadersModel) {
    this._associatedHeaders = value;
  }

  get periodContextCurrentYearMonth(): string {
    return this._periodContextCurrentYearMonth;
  }

  set periodContextCurrentYearMonth(value: string) {
    this._periodContextCurrentYearMonth = value;
  }

  get maxFileSize(): number {
    return this._maxFileSize;
  }

  set maxFileSize(value: number | null) {
    this._maxFileSize = value;
  }

  get rows(): ROW_TYPE[] {
    return this._rows;
  }

  get userFileHeaders(): string[] {
    return this._userFileHeaders;
  }

  get file(): File {
    return this._file;
  }

  get fileContent(): any {
    return this._fileContent;
  }

  get fileTypes(): ImportFileType[] {
    return this._fileTypes;
  }

  set determinateTimelineFileType(value: boolean) {
    this._determinateTimelineFileType = value;
  }

  get recognizedTimelineFileType(): any {
    return this._recognizedTimelineFileType;
  }

  set recognizedTimelineFileType(value: any) {
    this._recognizedTimelineFileType = value;
  }

  abstract async fileContentToRows(e: unknown): Promise<void>;

  abstract getHeaderFromFile(headerRowIndex: number): string[];

  abstract transformAndValidatedRowsToRemoteDataFormat(config: UniversalImportConfigModel): object[];

  /**
   * Abban az esetben ha kiválasztott időszakra nincs adat, megvizsgáljuk, hogy van-e lehetőség más
   * időszakra navigálni
   */
  checkPeriodContextCanChange(): MayPeriodChangeModel | null {
    const rowPeriods: MayPeriodChangeModel[] = [];
    const options = this._associatedHeaders.options;

    if (isNil(this._periodContextCurrentYearMonth)) {
      return null;
    }

    this._rows?.forEach(row => {
      // Abban az esetben ha rowPeriods nagyobb mint egy, akkor egynél több időszak(periódus) értékei vannak a
      // feltöltött adatok között. Ebben az esetben nem lehetséges a periódus context váltás.
      if (rowPeriods.length > 1) {
        return null;
      }

      Object.entries(options.format).forEach(([key, value]) => {
        const foundIndex = this.findUserHeaderIndexByAssociatedHeaders(key);
        const cellValue = row[key];

        if (foundIndex > -1) {
          if (this._periodContextCurrentYearMonth !== null && isString(value.format_type) && value.format_type === 'DATE') {
            const dateValidationFormat = value.format;
            const convertedDate = _rollupMoment(cellValue, dateValidationFormat, true);
            const convertedDateYearMonth = convertedDate.format(this._periodContextCurrentYearMonthCheckDateFormat);

            // Ha nem adott period context hónapra vonatkozik a dátum akkor elmentjük ha még nem létezik a tömbben,.
            if (convertedDateYearMonth !== this._periodContextCurrentYearMonth) {
              const period: MayPeriodChangeModel = {
                year: convertedDate.year(),
                month: convertedDate.month(),
              };

              const found = rowPeriods.find(item => {
                return item.year === period.year && item.month === period.month;
              });

              if (!found) {
                rowPeriods.push(period);
              }
            }
          }
        }
      });
    });
    return rowPeriods.length === 1 ? rowPeriods[0] : null;
  }

  checkFileType(file: File): boolean {
    console.log('[WORKER]', 'checkFileType', 'file types:', this.fileTypes, 'file type:', file.type, 'file name:', file.name);
    const fileType = this.fileTypes.find(_fileType => _fileType.types.some(type => type.indexOf(file.type) > -1));
    if (fileType === undefined) {
      const fileExtension = file.name.split('.').pop();
      if (this._fileTypes.reduce((prev, curr) => prev.concat(curr.extensions), [] as string[]).indexOf(fileExtension) === -1) {
        return false;
      } else {
        this._fileImportFileType = this._fileTypes.find(_fileType => _fileType.extensions.indexOf(fileExtension));
      }
    } else {
      this._fileImportFileType = fileType;
    }
    return true;
  }

  /**
   * Egy ellenőrző függvény ami akkor ad vissza igaz értéket, ha a kiválasztott fájl típusa ZIP.
   * @param file {File}
   */
  isZIP(file?: File): boolean {
    const uploadedFile = !isNil(file) ? file : this._file;
    return uploadedFile?.type?.toLocaleLowerCase().indexOf('zip') > -1 || false;
  }

  async setFile(file: File, headerRowIndex: number): Promise<undefined | Error> {
    console.log('[WORKER]', 'setFile', 'call');
    if (isNil(file)) {
      console.log('[WORKER]', 'setFile', 'not found file');
      return new Error(`File can't be undefined or null`);
    }
    this.abortFileReadAndClear();

    const isZIPFile = this.isZIP(file);

    if (!isZIPFile && !this.checkFileSize(file)) {
      throw new FileSizeError();
    }
    if (!this.checkFileType(file)) {
      throw new FileTypeError();
    }

    this._file = file;
    try {
      console.log('[WORKER]', 'Check file type(JSON or ZIP)');

      if (isZIPFile === true) {
        console.log('[WORKER]', 'Unzipping file...');
        const generatedJSONFileName = this.generateJSONFileName();
        await this.readFileFromZip(this._file, generatedJSONFileName);
      } else {
        await this.readFile();
      }

      // Apple vagy Andorid-os készülékről származó JSON fájl esetében detektálni kell a fájl típusát és visszaadni
      // majd, hogy dynamic setting-ből milyen kulcssal kell kiolvasni a header értékét.
      if (this._determinateTimelineFileType === true) {
        console.log('[WORKER]', 'Trying determinate Apple or Android JSON file type...');
        if (this._fileContent?.semanticSegments) {
          console.log('[WORKER]', 'Determinate file type: ANDROID');
          this.recognizedTimelineFileType = RecognizedTimelineFileTypeEnum.ANDROID;
        } else if (
          Array.isArray(this._fileContent) &&
          (this._fileContent[0]?.visit || this._fileContent[0]?.activity || this._fileContent[0]?.timelinePath)
        ) {
          console.log('[WORKER]', 'Determinate file type: APPLE');
          this.recognizedTimelineFileType = RecognizedTimelineFileTypeEnum.APPLE;
        } else {
          throw new UnknownTimelineTypeError();
        }
      }
      this._userFileHeaders = this.getHeaderFromFile(headerRowIndex);
    } catch (error) {
      console.error('[WORKER]', 'readFile', error);
      if (error instanceof ZipContentError || error instanceof UnknownTimelineTypeError) {
        throw error;
      }
      throw new FileParseError();
    }
  }

  /**
   * Kiválasztott év-hónap alapján generálunk a Google TimeLine-ban használatos fájl nevet
   * pl.: 2023_APRIL.json
   * @private
   */
  private generateJSONFileName(): string {
    const [periodYear, periodMonth] = this.periodContextCurrentYearMonth.split('-');
    const months = [
      'JANUARY',
      'FEBRUARY',
      'MARCH',
      'APRIL',
      'MAY',
      'JUNE',
      'JULY',
      'AUGUST',
      'SEPTEMBER',
      'OCTOBER',
      'NOVEMBER',
      'DECEMBER',
    ];

    return `${periodYear}_${months[Number(periodMonth) - 1]}.json`;
  }

  /**
   * Megadott időszakara vonatkozó JSON file keresése a ZIP fájlban.
   * @param file {File} ZIP file személyesen
   * @param fileName {string} Fájl neve amit a ZIP tartalmában keresünk.
   */
  async readFileFromZip(file: File, fileName: string): Promise<string> {
    let fileContent = null;
    const zip = new JSZip();
    const zipContent = await zip.loadAsync(file);
    const foundedFilePath = Object.keys(zip.files).find(filename => filename.indexOf(fileName) > -1);

    if (!isNil(foundedFilePath)) {
      fileContent = await zipContent.file(foundedFilePath)?.async('text');
      await this.fileContentToRows(fileContent);
    }

    if (!isNil(fileContent)) {
      return fileContent;
    } else {
      console.log('[WORKER]', `A fájl nem található a ZIP archívumban: ${fileName}`);
      throw new ZipContentError();
    }
  }

  private isNativeFunction(f: Function): boolean {
    return f.toString().includes('[native code]');
  }

  private abortFileReadAndClear() {
    if (isFunction(this.readerAbort) && !this.isNativeFunction(this.readerAbort)) {
      this.readerAbort();
      delete this.readerAbort;
    }
    delete this._rows;
    delete this._file;
  }

  checkFileSize(file: File): boolean {
    console.log('[WORKER]', 'checkFileSize', 'file size:', file.size, '|', 'max file size:', this.maxFileSize);
    return file.size / 1024 / 1024 <= this.maxFileSize;
  }

  protected readFile() {
    console.log('[WORKER]', 'readFile', 'call');
    if (Array.isArray(this._rows) && this._rows.length > 0) {
      return Promise.resolve();
    }

    return new Promise((resolve, reject) => {
      const reader: FileReader = new FileReader();
      this.readerAbort = reader.abort;

      reader.onloadstart = () => {
        console.log('[WORKER]', 'reader.onloadstart');
      };

      reader.onloadend = () => {
        console.log('[WORKER]', 'reader.onloadend');
      };

      reader.onabort = () => {
        console.log('[WORKER]', 'reader.onabort');
      };

      reader.onerror = e => {
        console.log('[WORKER]', 'reader.onerror', e);
        reader.abort();
        reject(e);
      };
      reader.onload = (e: any) => this.fileContentToRows(e.target.result).then(resolve).catch(reject);

      if (this._fileImportFileType.contentType === 'text') {
        reader.readAsText(this._file, 'UTF-8');
      } else {
        reader.readAsArrayBuffer(this._file);
      }
    });
  }

  protected findUserHeaderIndexByAssociatedHeaders(searchKey: string) {
    return this._associatedHeaders.headers.findIndex(header => header.requiredHeader.key === searchKey);
  }

  reset() {
    this._file = null;
    this._userFileHeaders = null;
    this.readerAbort = null;
    this._rows = null;
  }
}
