<div *transloco="let t; read: 'USER.SOCIAL'">
  <div style="display: grid">
    <div
      *ngIf="registrationType === registrationEnumType.REG_TYPE_B && isUsAppType === true && mode === 'REGISTRATION'"
      class="hr-container"
    >
      <span class="hr-text">Or</span>
    </div>
    <!--
      <button
        *ngIf="isUsAppType === true"
        mat-raised-button
        class="facebook social-login-button mb-3"
        (click)="onClickFacebookLogin()"
        [disabled]="form.disabled">
        <img src="/assets/social/login/facebook/social-login.png" />
        <span>{{ t('LOGIN_WITH_FACEBOOK') }}</span>
      </button>
-->
    <button
      *ngIf="isUsAppType === true && (mode === 'LOGIN' || registrationType === registrationEnumType.REG_TYPE_B)"
      mat-button
      class="google social-login-button {{ buttonType }}"
      [ngClass]="{ 'mb-3': isUsAppType === true }"
      (click)="onClickGoogleLogin()"
      [disabled]="form.disabled"
      [attr.id]="registrationType === registrationEnumType.REG_TYPE_A ? 'google-a-button' : 'google-b-button'"
    >
      <div class="d-flex justify-content-center" style="gap: 10px">
        <img src="/assets/social/login/google/google-logo.svg" style="width: 24px; height: auto" alt="google" />
        <span>{{ t(getTranslationKeyGoogle()) }}</span>
      </div>
    </button>

    <button
      *ngIf="isUsAppType === true && (mode === 'LOGIN' || registrationType === registrationEnumType.REG_TYPE_B)"
      mat-button
      class="google social-login-button {{ buttonType }}"
      [ngClass]="{ 'mb-3': isUsAppType === true }"
      (click)="onClickAppleLogin()"
      [disabled]="form.disabled"
    >
      <div class="d-flex justify-content-center" style="gap: 10px">
        <img src="/assets/social/login/apple/apple-logo.svg" style="width: 24px; height: auto" alt="apple" />
        <span>{{ t(getTranslationKeyApple()) }}</span>
      </div>
    </button>

    <mat-card *ngIf="isUsAppType === false || (mode === 'REGISTRATION' && registrationType === registrationEnumType.REG_TYPE_A)">
      <button
        mat-button
        [ngClass]="{ 'with-email': buttonType === 'mat-stroked-button' }"
        class="w-100 {{ buttonType }}"
        color="{{ buttonColor }}"
        (click)="onClickWithEmail()"
        [disabled]="form.disabled"
        [ngStyle]="{
          'background-color': isUsAppType ? '#57a9c4' : '',
          color: isUsAppType ? '#fff' : ''
        }"
      >
        <mat-icon [svgIcon]="withEmailIcon"></mat-icon>
        <span class="button-text">{{ withEmailButtonTranslateKey | transloco }}</span>
      </button>
    </mat-card>

    <div *ngIf="isUsAppType === true && mode === 'LOGIN'" class="hr-container">
      <span class="hr-text">Or</span>
    </div>
  </div>
</div>
