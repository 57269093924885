import { HttpClient } from '@angular/common/http';
import { Injector } from '@angular/core';
import { Store } from '@ngxs/store';
import { AbstractEntityService, BatchRequestModel, HttpListResponseModel } from '@roadrecord/utils';
import { AuthState } from '@roadrecord/user/common';
import { ClientInformation, UniversalAssociatedHeadersModel, UniversalImportConfigModel } from '@roadrecord/worker/universal-import';
import { UniversalImportHeadersModel } from './model/universal-import-headers.model';
import { UniversalImportWorkerBridgeService } from './universal-import-worker-bridge.service';
import { environment } from '@roadrecord/environment';
import { Observable } from 'rxjs';

export class UniversalImportService<HTTP_MODEL> extends AbstractEntityService<HttpListResponseModel<HTTP_MODEL>, HTTP_MODEL> {
  constructor(http: HttpClient, private store: Store, private injector: Injector, entityUrl: string, entityDefaultOrder: string) {
    super(entityUrl, entityDefaultOrder, http, true);
  }

  getModelIdValue(model: HTTP_MODEL): any {
    return null;
  }

  getToString(model: HTTP_MODEL): string {
    return JSON.stringify(model);
  }

  getStatusBatch(): BatchRequestModel {
    return { method: 'get', relative_url: `/${this.getBaseApiEndpoint()}/${this.entityUrl}/status/` };
  }

  getRequireHeaders(type: string) {
    return this.http.get<UniversalImportHeadersModel>(`${this.getEntityApiUrl()}/headers/`, {
      params: {
        'source-type': type,
      },
    });
  }

  async sendData(
    associateHeaders: UniversalAssociatedHeadersModel,
    cb: any,
    source_type,
    config: UniversalImportConfigModel = { removeFirstRowsNumber: 0 }
  ) {
    const universalImportWorkerBridgeService = this.injector.get(UniversalImportWorkerBridgeService);
    await universalImportWorkerBridgeService.setAssociateHeaders(associateHeaders);

    return await universalImportWorkerBridgeService.sendData(
      `${environment.api2Url}${this.entityUrl}/start`,
      this.store.selectSnapshot(AuthState.token),
      cb,
      source_type,
      config
    );
  }

  sendProcessEvent(payload: ClientInformation): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}event-save/web/`, payload);
  }
}
