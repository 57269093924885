import { UniversalAssociatedHeadersModel } from '../../model/universal-associated-headers.model';

export type UniversalImportControllerType =
  | 'EXCEL_OR_CSV'
  | 'GOOGLE_TIMELINE_JSON'
  | 'GOOGLE_TIMELINE_APPLE_OR_ANDROID'
  | 'TESSIE'
  | 'TOYOTA';

export enum UniversalImportControllerTypeEnum {
  EXCEL_OR_CSV = 'EXCEL_OR_CSV',
  GOOGLE_TIMELINE_JSON = 'GOOGLE_TIMELINE_JSON',
  GOOGLE_TIMELINE_APPLE_OR_ANDROID = 'GOOGLE_TIMELINE_APPLE_OR_ANDROID',
}

export enum RecognizedTimelineFileTypeEnum {
  APPLE,
  ANDROID,
}

export interface UniversalImportControllerOptions {
  associatedHeaders: UniversalAssociatedHeadersModel | null;
  periodContextCurrentYearMonth: string;
  periodContextCurrentYearMonthCheckDateFormat: string;
  maxFileSize: number;
}

export enum ImportFileTypeEnum {
  CSV,
  EXCEL,
  GOOGLE_TIMELINE_JSON,
  GOOGLE_TIMELINE_APPLE_OR_ANDROID,
}

export interface ImportFileType {
  name: ImportFileTypeEnum;
  types: string[];
  extensions: string[];
  contentType: 'text' | 'binary';
}

export enum TimelineProcessEventTypeEnum {
  TIMELINE_FILE_READ = 'TIMELINE_FILE_READ',
  TIMELINE_FILE_PROCESSED_AND_SENT = 'TIMELINE_FILE_PROCESSED_AND_SENT',
  TIMELINE_FILE_VALIDATION_ERROR = 'TIMELINE_FILE_VALIDATION_ERROR',
  TIMELINE_FILE_NO_DATA_IN_SELECTED_PERIOD = 'TIMELINE_FILE_NO_DATA_IN_SELECTED_PERIOD',
  TIMELINE_SERVER_ERROR = 'TIMELINE_SERVER_ERROR',
}

export interface BrowserInformation {
  name: string;
  major: string;
  version: string;
}

export interface OSInformation {
  name: string;
  version: string;
}

export interface ClientInformation {
  os: OSInformation;
  browser: BrowserInformation;
  platform: string;
  file_size: string;
  ram?: string;
  js_heap?: string;
  cpu_core?: string;
  event_time?: Date;
  event_type?: TimelineProcessEventTypeEnum;
}
