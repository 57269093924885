import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslocoModule } from '@ngneat/transloco';
import { ProgressWhenFirstLoadingModule } from '@roadrecord/progress-when-first-loading';
import { ValidatingModule } from '@roadrecord/validating';
import { SettingsComponent } from './settings.component';
import { IMaskModule } from 'angular-imask';
import { AppTypePipeModule } from '@roadrecord/common/common';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { RouterModule } from '@angular/router';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTableModule } from '@angular/material/table';
import { PeriodContextDisplayModule } from '@roadrecord/app-layout/common';
/*
import { DateFormatPipe } from './pipe/date-format.pipe';
*/
import { TranslocoUtilsDirectiveModule } from '@roadrecord/transloco-utils';
import { SharedModule } from './pipe/shared.module';

@NgModule({
  imports: [
    CommonModule,

    ReactiveFormsModule,
    TranslocoModule,
    ValidatingModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MatSliderModule,
    MatButtonModule,
    MatCardModule,
    FlexLayoutModule,
    ProgressWhenFirstLoadingModule,
    MatProgressBarModule,
    MatIconModule,
    MatTooltipModule,
    MatButtonToggleModule,
    MatSelectModule,
    MatSnackBarModule,
    IMaskModule,
    MatSlideToggleModule,
    AppTypePipeModule,
    RouterModule,
    MatTabsModule,
    MatTableModule,
    PeriodContextDisplayModule,
    TranslocoUtilsDirectiveModule,
    SharedModule,
  ],
  /*
  declarations: [SettingsComponent, DateFormatPipe],
*/
  declarations: [SettingsComponent],
  exports: [SettingsComponent],
})
export class SettingsModule {}
