import { animate, style, transition, trigger } from '@angular/animations';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChildren,
} from '@angular/core';
import { AbstractControlOptions, FormControl, Validators } from '@angular/forms';
import { MatSnackBar, MatSnackBarRef, MatSnackBarVerticalPosition, SimpleSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { checkPropertyChange, deepEqual, RRFormGroup } from '@roadrecord/common/common';
import { NotEntitiesBackgroundImageType, SearchFieldErrorStateMatcher } from '@roadrecord/grid';
import { MaybeHandleHttpError } from '@roadrecord/utils';
import { MessageDialogService, MessageDialogTypeEnum } from '@roadrecord/message-dialog';
import { isNil, isNotEmptyString } from '@roadrecord/type-guard';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Subject, Subscription, timer } from 'rxjs';
import { first, take } from 'rxjs/operators';
import { PartnerService } from '../../partner.service';
import { checkIsValidCoordinate } from '../function/check-is-valid-coordinate.function';
import { notFoundCoordinate } from '../function/not-found-coordinate.function';
import { LocationModel, PartnerTypeEnum } from '@roadrecord/partner/model';
import { fadeIn2XFastEnter } from '@roadrecord/animations';
import { NotificationsService } from '@roadrecord/external-packages/angular2-notifications';
import { filterAddresses, filterCompanies, filterHeadquarters } from '../pipes/filter-locations.pipe';
import { searchOnMapCMPAfterSearchByPartnerNameOrAddressHook } from './search-on-map-cmp-after-search-by-partner-name-or-address.hook';
import { searchOnMapCMPOnDestroyHook } from './search-on-map-cmp-on-destroy.hook';
import { MapBoxComponent } from '@roadrecord/map-box';
import { tsDeepcopy } from '@roadrecord/ts-deepcopy';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

const logScopeName = 'SearchOnMapComponent';

const defaultValuesFilterForm = { companies: true, headquartes: true, addresses: true };

@UntilDestroy()
@Component({
  selector: 'rr-search-on-map',
  templateUrl: './search-on-map.component.html',
  styleUrls: ['./search-on-map.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    fadeIn2XFastEnter,
    trigger('enterAnimation', [
      transition(':enter', [
        style({
          opacity: 0,
        }),
        animate(
          '400ms cubic-bezier(0.25, 0.8, 0.25, 1)',
          style({
            opacity: 1,
          })
        ),
      ]),
    ]),
  ],
})
export class SearchOnMapComponent implements OnChanges, OnInit, OnDestroy, AfterViewInit {
  PartnerTypeEnum = PartnerTypeEnum;
  @HostBinding('@enterAnimation')
  enterAnimation;
  searchControl = new FormControl(undefined, {
    validators: Validators.compose([Validators.required, Validators.minLength(3)]),
    updateOn: 'change',
  } as AbstractControlOptions);
  searchFieldErrorMatcher = new SearchFieldErrorStateMatcher();
  loading = false;
  valid = false;
  remoteError: string;
  searchInputTranslatedLabel: string;
  searchInputTranslatedPlaceholder: string;
  @Input()
  hasModel = false;
  @Input()
  selectedLocation: LocationModel;
  @Input()
  coordinateLoading = false;
  @Input()
  id: number;
  @Input()
  readonly partnerType: PartnerTypeEnum;
  @Input()
  readonly isNew: boolean;
  @Input()
  searchStringText = '';
  @Input() fragmentDialogSearchValue: string;
  @Input()
  hasBackButton = true;
  @Input() readonly mapboxMode: boolean;
  @Input() readonly outsideMarkers: GeoJSON.Feature<GeoJSON.Point, GeoJSON.GeoJsonProperties>[];
  @Output()
  cancel = new EventEmitter<void>();
  @Output()
  save = new EventEmitter<LocationModel>();
  @Output()
  markers = new EventEmitter<GeoJSON.Feature<GeoJSON.Point, GeoJSON.GeoJsonProperties>[]>();
  @Output()
  searchResult = new EventEmitter<LocationModel[]>();
  @Output()
  searchString = new EventEmitter<string>();
  @Output() readonly newPoi = new EventEmitter<[number, number]>();
  @ViewChildren(MapBoxComponent) private mapBoxComponent: QueryList<MapBoxComponent>;
  @Input() backgroundImage: NotEntitiesBackgroundImageType;
  filterFormCompaniesControl = new FormControl({ value: true, disabled: true });
  filterFormHeadquartesControl = new FormControl({ value: true, disabled: true });
  filterFormAddressesControl = new FormControl({ value: true, disabled: true });
  filterForm = new RRFormGroup({
    companies: this.filterFormCompaniesControl,
    headquartes: this.filterFormHeadquartesControl,
    addresses: this.filterFormAddressesControl,
  });
  firstSearched = true;
  private weRefreshListDataMatSnackBarRef: MatSnackBarRef<SimpleSnackBar>;
  private preventSimpleClick: boolean;
  private timer: Subscription;
  private searchSubscription: Subscription;
  private waitGetLocation$ = new Subject<LocationModel>();
  private lastSearchValue: string;
  private inited = false;
  currentMarkers: GeoJSON.Feature<GeoJSON.Point, GeoJSON.GeoJsonProperties>[];

  constructor(
    private partnerService: PartnerService,
    private cdr: ChangeDetectorRef,
    private route: ActivatedRoute,
    private translocoService: TranslocoService,
    private matSnackBar: MatSnackBar,
    private gtmService: GoogleTagManagerService,
    private messageDialogService: MessageDialogService,
    private notificationsService: NotificationsService,
    private ngZone: NgZone
  ) {
    this.markers.subscribe(markers => (this.currentMarkers = markers));
  }

  private _results: LocationModel[];

  get results(): LocationModel[] {
    return this._results;
  }

  @Input()
  set results(value: LocationModel[]) {
    const filterFormValues = { ...defaultValuesFilterForm };
    if (Array.isArray(value) && value.length > 0) {
      if (filterCompanies(value).length > 0) {
        this.filterFormCompaniesControl.enable();
      } else {
        this.filterFormCompaniesControl.disable();
        filterFormValues.companies = false;
      }
      if (filterHeadquarters(value).length > 0) {
        this.filterFormHeadquartesControl.enable();
      } else {
        this.filterFormHeadquartesControl.disable();
        filterFormValues.headquartes = false;
      }
      if (filterAddresses(value).length > 0) {
        this.filterFormAddressesControl.enable();
      } else {
        this.filterFormAddressesControl.disable();
        filterFormValues.addresses = false;
      }
      this.firstSearched = false;
    }
    this.filterForm.patchValue(filterFormValues);
    this._results = value;
  }

  private _snackbarWeRefreshListDataPosition: MatSnackBarVerticalPosition = 'top';

  @Input()
  set snackbarWeRefreshListDataPosition(value: MatSnackBarVerticalPosition) {
    if (value !== undefined) {
      this._snackbarWeRefreshListDataPosition = value;
    }
  }

  ngOnDestroy(): void {
    if (!isNil(this.weRefreshListDataMatSnackBarRef)) {
      this.weRefreshListDataMatSnackBarRef.dismiss();
    }
    searchOnMapCMPOnDestroyHook(this.notificationsService);
  }

  ngOnInit(): void {
    this.getSearchParameterAndSetFilterControl();

    if (!isNil(this.selectedLocation)) {
      this.valid = true;
      this.markers.emit([
        {
          type: 'Feature',
          geometry: { type: 'Point', coordinates: [+this.selectedLocation.longitude, +this.selectedLocation.latitude] },
          properties: {
            title: this.selectedLocation.name,
            content: this.selectedLocation.address,
          },
        },
      ]);
    }
    this.inited = true;
    this.setSearchInputPlaceholderTranslateKey();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.results !== undefined && changes.results.currentValue !== undefined) {
      this.remoteError = undefined;
    } else if (this.inited === true && checkPropertyChange('searchStringText', changes)) {
      this.searchControl.patchValue(changes.searchStringText.currentValue);
    }
    if (!isNil(changes.outsideMarkers)) {
      this.currentMarkers = tsDeepcopy(this.outsideMarkers);
    }
  }

  onBlurSearchField(): void {
    if (this.searchControl.valid) {
      this.searchControl.markAsUntouched();
      this.searchControl.markAsPristine();
    }
  }

  onFocusSearchField(): void {
    this.searchControl.markAsDirty();
  }

  onSelectPartner(locationModel: LocationModel): void {
    this.stopClickTimer();
    this.preventSimpleClick = false;
    const delay = 250;
    this.timer = timer(delay).subscribe(() => {
      if (!this.preventSimpleClick) {
        this.getLocation(locationModel);
      }
    });
  }

  onSelectPartnerDblClick(locationModel: LocationModel): void {
    this.preventSimpleClick = true;
    this.stopClickTimer();

    this.waitGetLocation$.pipe(first()).subscribe(remoteLocationModel => {
      if (remoteLocationModel !== undefined) {
        this.onSave(remoteLocationModel);
      }
    });
    this.getLocation(locationModel);
  }

  onCancel(): void {
    if (this.loading) {
      if (this.searchSubscription !== undefined && !this.searchSubscription.closed) {
        this.searchSubscription.unsubscribe();
      }
      this.loading = false;
      this.lastSearchValue = '';
      return this.searchControl.enable();
    }
    this.cancel.emit();
  }

  onSearch(): void {
    this.valid = true;
    this.searchControl.markAsTouched();
    if (this.searchControl.valid) {
      if (this.lastSearchValue === this.searchControl.value) {
        // ha ugyan arra akar keresni mint ami a listán van
        return;
      }
      this.lastSearchValue = this.searchControl.value;
      this.searchString.emit(this.lastSearchValue);
      this.searchControl.disable();
      this.selectedLocation = undefined;
      this.loading = true;
      this.remoteError = undefined;
      const finallyCb = () => {
        this.loading = false;
        this.searchControl.enable();
      };
      this.searchSubscription = this.partnerService
        .searchByPartnerNameOrAddress(this.searchControl.value)
        .pipe(untilDestroyed(this))
        .subscribe(
          response => {
            this.results = response.results;
            this.searchResult.emit(this._results);
            searchOnMapCMPAfterSearchByPartnerNameOrAddressHook(
              this.partnerType,
              response,
              this.notificationsService,
              this.translocoService
            );
            this.updateMap();

            this.gtmService.pushTag({
              event: 'search',
              search_term: this.searchControl.value,
              num_items: response.count,
            });
          },
          error => {
            this.lastSearchValue = '';
            MaybeHandleHttpError.maybeHandleHttpError(error, finallyCb);
            console.warn(logScopeName, error);
          },
          finallyCb.bind(this)
        );
    }
  }

  private updateMap() {
    if (this.ngZone.isStable) {
      this.mapBoxComponent.first.onLoadedMap();
    } else {
      this.ngZone.onStable.pipe(take(1), untilDestroyed(this)).subscribe(() => this.mapBoxComponent.first.onLoadedMap());
    }
  }

  private onSave(locationModel?: LocationModel): void {
    if (locationModel !== undefined) {
      this.selectedLocation = locationModel;
    }
    if (!checkIsValidCoordinate(this.selectedLocation)) {
      return notFoundCoordinate(this.selectedLocation.name, this.translocoService, this.messageDialogService);
    }
    this.save.emit(this.selectedLocation);
  }

  private setSearchInputPlaceholderTranslateKey(): void {
    switch (this.partnerType) {
      case PartnerTypeEnum.PARTNER:
        this.searchInputTranslatedLabel = 'PARTNER.DETAILS.DATA_FORM.LABEL.SEARCH_HELP.PARTNER';
        this.searchInputTranslatedPlaceholder = 'PARTNER.DETAILS.DATA_FORM.LABEL.SEARCH_HELP.PLACE_HOLDER.PARTNER';
        break;
      case PartnerTypeEnum.HEAD_OFFICE_OR_SITE:
        this.searchInputTranslatedLabel = 'PARTNER.DETAILS.DATA_FORM.LABEL.SEARCH_HELP.HEAD_OFFICE_OR_SITE';
        this.searchInputTranslatedPlaceholder = 'PARTNER.DETAILS.DATA_FORM.LABEL.SEARCH_HELP.PLACE_HOLDER.HEAD_OFFICE_OR_SITE';
        break;
      case PartnerTypeEnum.CHARGING_STATION:
        this.searchInputTranslatedLabel = 'PARTNER.DETAILS.DATA_FORM.LABEL.SEARCH_HELP.CHARGING_STATION';
        this.searchInputTranslatedPlaceholder = 'PARTNER.DETAILS.DATA_FORM.LABEL.SEARCH_HELP.PLACE_HOLDER.CHARGING_STATION';
        break;
    }
  }

  private getSearchParameterAndSetFilterControl(): void {
    if (isNotEmptyString(this.fragmentDialogSearchValue)) {
      this.searchControl.patchValue(this.fragmentDialogSearchValue);
      this.onSearch();
    } else {
      this.searchControl.patchValue(this.searchStringText);
    }
  }

  private getLocation(locationModel: LocationModel): void {
    if (this.valid === true || this.selectedLocation !== locationModel) {
      this.selectedLocation = locationModel;
      this.valid = true;

      this.coordinateLoading = true;
      this.cdr.markForCheck();
      this.getLocationByHash(locationModel);
    }
  }

  private getLocationByHash(locationModel: LocationModel): void {
    if (locationModel.is_valid === true) {
      this.coordinateLoading = false;
      this.valid = true;
      this.waitGetLocation$.next(locationModel);
      return this.markers.emit([
        {
          type: 'Feature',
          geometry: { type: 'Point', coordinates: [+locationModel.longitude, +locationModel.latitude] },
          properties: {
            title: locationModel.name,
            content: locationModel.address,
          },
        },
      ]);
    }
    this.partnerService
      .getCoordinatesByAddress(locationModel)
      .pipe(untilDestroyed(this))
      .subscribe(
        location => {
          this.coordinateLoading = false;
          if (!isNil(location)) {
            if (checkIsValidCoordinate(location as any)) {
              this.valid = true;

              const index = this._results.findIndex(listLocation => listLocation === this.selectedLocation);
              // hash kell vissza a list ngTrackBy miatt
              this._results[index] = { ...location, hash: this._results[index].hash };
              this.results = [...this._results];
              this.selectedLocation = location as any;

              if (deepEqual(locationModel, location)) {
                this.translocoService
                  .selectTranslate(['PARTNER.DETAILS.SEARCH_ON_MAP.SNACKBAR.WE_REFRESH_LIST_DATA', 'COMMON.ACTION.CLOSE_LOWER'])
                  .pipe(untilDestroyed(this))
                  .subscribe(translatedTexts => {
                    this.weRefreshListDataMatSnackBarRef = this.matSnackBar.open(translatedTexts[0], translatedTexts[1], {
                      verticalPosition: this._snackbarWeRefreshListDataPosition,
                    });
                  });
              }
              // dblclick jelzese
              this.waitGetLocation$.next(location as any);

              this.cdr.markForCheck();

              return this.markers.emit([
                {
                  type: 'Feature',
                  geometry: { type: 'Point', coordinates: [+(location as any).longitude, +(location as any).latitude] },
                  properties: {
                    title: (location as any).name,
                    content: (location as any).address,
                  },
                },
              ]);
            }
          }
          notFoundCoordinate(locationModel.name, this.translocoService, this.messageDialogService);
          this.valid = false;
          // dblclick jelzese
          this.waitGetLocation$.next(undefined);
        },
        () => {
          this.coordinateLoading = false;
          this.valid = false;
          this.messageDialogService
            .open({
              id: null,
              type: MessageDialogTypeEnum.ERROR,
              text: `${this.translocoService.translate('COMMON.CONTACT_TO_SUPPORT_LINK', {
                before: this.translocoService.translate('PARTNER.DETAILS.SEARCH_ON_MAP.NOT_FOUND_HASH'),
              })}`,
              translateText: false,
              htmlMode: true,
            })
            .afterClosed()
            .subscribe(() => this.cdr.markForCheck());
        },
        () => this.cdr.markForCheck()
      );
  }

  private stopClickTimer(): void {
    if (this.timer !== undefined && !this.timer.closed) {
      this.timer.unsubscribe();
    }
  }

  onDragendMap($event: [number, number]) {
    this.newPoi.emit($event);
  }

  ngAfterViewInit() {
    timer(500).subscribe(() => {
      this.updateMap();
    });
  }
}
