import {
  AfterContentChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  HostListener,
  Inject,
  Injector,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { Actions, ofActionDispatched, ofActionSuccessful, Select, Store } from '@ngxs/store';
import {
  globalEmailControlValidationMessages,
  globalPasswordControlValidationMessages,
  slideLeftInUnathAnimation,
  slideLeftUnathOutAnimation,
  USER_PASSWORD_RESENT,
  ViewportService,
} from '@roadrecord/common/common';
import {
  FieldHttpError,
  HandledHttpError,
  HandleErrorObject,
  manualHandleFormControlsSetServerError,
  ManualViewRemoteFieldErrorPlugin,
  MaybeHandleHttpError,
} from '@roadrecord/utils';
import { MessageDialogService } from '@roadrecord/message-dialog';
import { isMobileOrTablet } from '@roadrecord/mobile-detect';
import { isNil } from '@roadrecord/type-guard';
import { rrFormErrorStateMatcher } from '@roadrecord/validating';
import { CookieService } from 'ngx-cookie-service';
import { asapScheduler, Observable, timer } from 'rxjs';
import { filter, first } from 'rxjs/operators';
// tslint:disable-next-line:nx-enforce-module-boundaries
import { RegistrationErrorAction } from '../../../../../../common/src/lib/state/action/register/registration-error.action';
// tslint:disable-next-line:nx-enforce-module-boundaries
import { RegistrationSuccessAction } from '../../../../../../common/src/lib/state/action/register/registration-success.action';
// tslint:disable-next-line:nx-enforce-module-boundaries
import { AuthService } from '../../../../../../common/src/lib/authentication/auth.service';
// tslint:disable-next-line:nx-enforce-module-boundaries
import { UserModel } from '../../../../../../common/src/lib/authentication/model/user.model';
// tslint:disable-next-line:nx-enforce-module-boundaries
import { fromForgotPassword } from '../../../../../../common/src/lib/authentication/layout/forgot-password/forgot-password/from-forgot-password';
// tslint:disable-next-line:nx-enforce-module-boundaries
import { canRegisterSuccessFulVisiblePage } from '../../../../../../common/src/lib/authentication/layout/register/registration-successful/registration-successful.component';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { DISABLE_VIEW_REMOTE_FIELD_ERROR_FORMLY_EXTENSION } from '@roadrecord/dynamic-form';
// tslint:disable-next-line:nx-enforce-module-boundaries
import { getRegisterReferer } from '../../../../../../common/src/lib/authentication/layout/register/get-register-referer/get-register-referer-rr.function';
import { userRegistrationNameFormFragmentDynamicConfig } from '../../../dynamic-form/user-registration-name-form-fragment-dynamic.config';
import { DOCUMENT } from '@angular/common';
// tslint:disable-next-line:nx-enforce-module-boundaries
import { FingerprintService } from '../../../../../../common/src/lib/authentication/layout/fingerprint/fingerprint.service';
import { environment } from '@roadrecord/environment';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { EmailValidators, PasswordValidators, UniversalValidators } from 'ngx-validators';
// tslint:disable-next-line:nx-enforce-module-boundaries
import { USRegistrationAction } from '../../../../../../common/src/lib/state/action/register/us-registration.action';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
// tslint:disable-next-line:nx-enforce-module-boundaries
import { getRegisterOptimonkReferer } from '../../../../../../common/src/lib/authentication/layout/register/get-register-referer/get-register-referer-mw.function';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
// tslint:disable-next-line:nx-enforce-module-boundaries
import { RegistrationEnumType } from '../../../../../../common/src/lib/authentication/model/register-social.model';

enum RegistrationTypePath {
  REGISTRATION_TYPE_PATH = 'registration-type',
}

/*
enum RegistrationTypeEnum {
  TYPE_A = 'type-a',
  TYPE_B = 'type-b',
}
*/

/**
 * ideiglenesen nem hasznaljuk most
 * @deprecated
 */
@Component({
  selector: 'rr-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
  providers: [
    FingerprintService,
    rrFormErrorStateMatcher,
    CookieService,
    {
      provide: DISABLE_VIEW_REMOTE_FIELD_ERROR_FORMLY_EXTENSION,
      useValue: true,
    },
  ],
  animations: [slideLeftInUnathAnimation, slideLeftUnathOutAnimation],
})
export class RegisterComponent implements OnInit, AfterViewInit, AfterContentChecked {
  isMobileOrTablet = isMobileOrTablet();

  @HostBinding('@enterAnimation')
  enterAnimation;

  @HostBinding('@.disabled')
  disabledAnimation = this.authService.unauthFirstAnimDisabled;

  @HostBinding('@leaveAnimation')
  leaveAnimation;

  @ViewChild('captchaRef')
  captchaRef: any;

  @Select(states => states.registerPage.loading)
  loading$: Observable<boolean>;

  @ViewChild('email') emailInputElement: ElementRef<HTMLInputElement>;

  @Input() loadCompanyRegistrationComplete = false;

  passwordType = 'password';
  visiblePassword = false;

  form: FormGroup & { submitted?: boolean };
  passwordControl = new FormControl('');
  magazineSubscribeControl = new FormControl(false);
  emailControl = new FormControl('', [Validators.required, EmailValidators.normal]);

  useCaptcha = environment.useCaptcha;
  readonly reCaptchaKey = environment.captchaCode;
  readonly nameFormFragmentFormlyFields: FormlyFieldConfig[];
  @Input() submitLabel = 'USER.REGISTER.CREATE';
  formModel: any;
  readonly emailControlValidationMessages = globalEmailControlValidationMessages;
  readonly passwordControlValidationMessages = globalPasswordControlValidationMessages;
  recaptchaImageWindowObserver: MutationObserver;
  recaptchaImageWindow: HTMLElement | null = null;
  private runCaptchaReset = false;
  private viewRemoteFieldErrorPlugin: ManualViewRemoteFieldErrorPlugin<any, any>;
  registrationEnumType = RegistrationEnumType;
  selectedRegistrationType = RegistrationEnumType.REG_TYPE_A;

  constructor(
    private store: Store,
    private router: Router,
    private actions: Actions,
    private injector: Injector,
    private cd: ChangeDetectorRef,
    private authService: AuthService<UserModel>,
    private viewportService: ViewportService,
    @Inject(DOCUMENT) private document: Document,
    private cookieService: CookieService,
    private gtmService: GoogleTagManagerService,
    private messageDialogService: MessageDialogService,
    private fingerprintService: FingerprintService
  ) {
    this.nameFormFragmentFormlyFields = userRegistrationNameFormFragmentDynamicConfig.config(this.injector);
  }

  ngOnInit(): void {
    this.initForm();
  }

  ngAfterContentChecked(): void {
    this.cd.detectChanges();
  }

  register(captchaResponse: string): void {
    const formValue = this.form.getRawValue();

    const user: any = {
      ...formValue,
      email: formValue.email,
      password: formValue.password,
      is_magazine: formValue.magazineSubscribe,
      captcha_token: captchaResponse || undefined,
      device_fingerprint: this.fingerprintService.getCode(),
      referrer: getRegisterReferer(this.cookieService, this.document),
      optimonk_referrer: getRegisterOptimonkReferer(this.cookieService, this.document),
    };

    const successActionSubscription = this.actions.pipe(ofActionSuccessful(RegistrationSuccessAction), first()).subscribe(action => {
      canRegisterSuccessFulVisiblePage.visible = true;
      this.router.navigate(['/registration-successful'], { queryParams: { email: formValue.email } });
    });

    this.actions.pipe(ofActionDispatched(RegistrationErrorAction), first()).subscribe((action: RegistrationErrorAction) => {
      successActionSubscription.unsubscribe();
      this.form.enable();
      if (this.useCaptcha) {
        this.runCaptchaReset = true;
        this.captchaRef.reset();
      }
      if (!(action.error instanceof HandledHttpError) && !isNil(this.messageDialogService.openedDialogRef)) {
        this.messageDialogService.openedDialogRef.close();
      }
      // Kezzel kezeljuk kulon a mezo szintu hibakat
      if (action.error instanceof FieldHttpError) {
        manualHandleFormControlsSetServerError(action.error, this.viewRemoteFieldErrorPlugin);
      }
      MaybeHandleHttpError.maybeHandleHttpError(action.error, () => {
        const conflict = 409;
        if (action.error.status !== undefined && action.error.status === conflict) {
          fromForgotPassword.is = true;
          asapScheduler.schedule(() =>
            HandleErrorObject.handleErrorObject(action.error).subscribe(() => {
              this.router.navigate([`/${USER_PASSWORD_RESENT}`]);
            })
          );
        } else {
          this.emailInputElement.nativeElement.focus();
        }
      });
    });

    // Ideiglenesen, amig nem lesz megcsinalva a cookit
    if (this.cookieService.check('_fbp')) {
      user.client_fbp = this.cookieService.get('_fbp');
    }
    // Ideiglenesen, amig nem lesz megcsinalva a cookit
    if (this.cookieService.check('_fbc')) {
      user.client_fbc = this.cookieService.get('_fbc');
    }

    this.store.dispatch(new USRegistrationAction(user, this.selectedRegistrationType));
  }

  @HostListener('@enterAnimation.done')
  onEnterDone(): void {
    this.viewportService.routerAnimation = false;
  }

  @HostListener('@enterAnimation.start')
  onLeaveStart(): void {
    this.viewportService.routerAnimation = true;
  }

  onSubmit(): void {
    this.form.submitted = true;
    if (this.form.invalid || this.store.selectSnapshot<boolean>(states => states.registerPage.loading) === true) {
      return;
    }

    this.form.disable();
    if (this.useCaptcha) {
      this.captchaRef.execute();
      /*
      this.initRecaptchaImageWindow();
*/
    } else {
      this.register('');
    }
  }

  /**
   *
   * @param $event
   */
  onResolveCaptcha($event: string): void {
    if (this.runCaptchaReset === true) {
      this.runCaptchaReset = false;
      return;
    }
    if ($event === null) {
      this.messageDialogService
        .openError({ id: null, text: 'COMMON.GOOGLE_RECAPTCHA_ERROR', htmlMode: true })
        .afterClosed()
        .subscribe(() => this.form.enable());
      return;
    }
    this.register($event);
  }

  ngAfterViewInit(): void {
    timer(0).subscribe(() => {
      if (this.authService.unauthFirstAnimDisabled === true) {
        this.authService.unauthFirstAnimDisabled = false;
        this.disabledAnimation = this.authService.unauthFirstAnimDisabled;
      }
    });
    // Focus az első beviteli mezőre
    setTimeout(() => {
      this.emailInputElement.nativeElement.focus();
    }, 1000);
  }

  /**
   *Navigáció a bejelentkezés űrlapra
   */
  onClickLoginWithEmail() {
    this.router.navigate(['login']);
  }

  /**
   * Jelszó mező láthatóságának beállítása
   */
  changeVisibilityPassword(): void {
    this.visiblePassword = !this.visiblePassword;
    this.passwordType === 'password' ? (this.passwordType = 'text') : (this.passwordType = 'password');
  }

  /**
   * Form kontrolok beállítása
   * @private
   */
  private initForm(): void {
    this.form = new FormGroup({
      email: this.emailControl,
      password: this.passwordControl,
      magazineSubscribe: this.magazineSubscribeControl,
    });

    const localStorageRegistrationType = localStorage.getItem(RegistrationTypePath.REGISTRATION_TYPE_PATH);

    if (!isNil(localStorageRegistrationType)) {
      this.selectedRegistrationType =
        localStorageRegistrationType === RegistrationEnumType.REG_TYPE_A
          ? RegistrationEnumType.REG_TYPE_A
          : RegistrationEnumType.REG_TYPE_B;
    } else {
      // US oldalon tesztként 2 fajta regisztrációs felület érhető el(A,B). Azt,hogy melyiket jelenítjük meg úgy kerül
      // eldöntélsre, hogy az aktuális idő perc értéke páros akkor A, ha páratlan szám akkor B.
      this.selectedRegistrationType = new Date().getMinutes() % 2 === 0 ? RegistrationEnumType.REG_TYPE_A : RegistrationEnumType.REG_TYPE_B;
    }

    localStorage.setItem(RegistrationTypePath.REGISTRATION_TYPE_PATH, this.selectedRegistrationType);

    this.initPasswordControlValidators();
    this.loadCompanyRegistrationComplete = coerceBooleanProperty(this.loadCompanyRegistrationComplete);

    this.gtmService.pushTag({
      category: 'Registration',
      event: this.selectedRegistrationType === RegistrationEnumType.REG_TYPE_A ? 'registration_a_loaded' : 'registration_b_loaded',
    });
  }

  /**
   * Jelszó mező validációjának beállítása
   * @private
   */
  private initPasswordControlValidators(): void {
    const passwordFieldsValidationsRules = [
      Validators.required,
      PasswordValidators.digitCharacterRule(1),
      PasswordValidators.lowercaseCharacterRule(1),
      PasswordValidators.uppercaseCharacterRule(1),
      UniversalValidators.noWhitespace,
      Validators.minLength(8),
      Validators.maxLength(20),
    ];
    this.passwordControl.setValidators(passwordFieldsValidationsRules);
  }

  private findRecaptchaImageWindow(): HTMLElement | null {
    // @ts-ignore
    return [...document.querySelectorAll('iframe')].find(iframe => iframe.src.includes('google.com/recaptcha/api2/bframe'))?.parentNode
      ?.parentNode as HTMLDivElement;
  }

  private initRecaptchaImageWindow() {
    if (isNil(this.recaptchaImageWindowObserver)) {
      // @ts-ignore
      this.recaptchaImageWindow = this.findRecaptchaImageWindow();

      if (!isNil(this.recaptchaImageWindow)) {
        this.recaptchaImageWindowObserver = new MutationObserver(() => {
          if (
            this.recaptchaImageWindow.style.visibility !== 'visible' ||
            this.recaptchaImageWindow.style.opacity !== '1' ||
            this.recaptchaImageWindow.style.top !== '10px'
          ) {
            this.recaptchaImageWindow.style.top = '10px';
            this.recaptchaImageWindow.style.opacity = '1';
            this.recaptchaImageWindow.style.visibility = 'visible';
          }
        });
        this.recaptchaImageWindowObserver.observe(this.recaptchaImageWindow, { attributeFilter: ['style'] });
      } else {
        console.log('RecaptchaImageWindow not found!');
      }
    }
  }
}
